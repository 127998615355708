// Config file

import { decryptStoredData } from 'app/pages/storetoken/StoreTokenCheck';
import { Config } from '../../constant/index';


export default class PP2Service {
  /**
   *
   * @param {string} url
   * @param {string} http_verb
   */

  static async fetch(
    url = "",
    http_verb = "get",
    data = null,
    contentType = "application/json",
    token = true
  ) {

    // const storedToken = localStorage.getItem(Config.adminApiTokenName)
    // const storedToken = localStorage.getItem(Config.adminApiTokenName)
    //  const storedToken = "2|RujWkc7WAoUeHnjYI7dTf7Xx4BHnHVKbZZLz4Mi8";
    const storedToken =  decryptStoredData(Config.adminApiTokenName) 

    var requestOptions = {
      method: http_verb,
      headers: {
        Authorization: "Bearer " + storedToken,
        Accept: "application/json",
        "Content-Type": contentType,
        // "X-Requested-With": "XMLHttpRequest",
        // "_token": "{{ csrf_token() }}",
        // 'x-csrf-token': document.querySelector('[name="csrf - token"]')
      },

    };

    console.log("storedToken:", url);

    if (http_verb.toLowerCase() !== "get") {
      requestOptions["body"] = JSON.stringify(data);
    }

    try {
      console.log("Config.serverApiUrl + url", Config.serverApiUrl + url);
      const response = await fetch(Config.serverApiUrl + url, requestOptions);

      const responseJson = await response.json();
      console.log('responseJson:', responseJson)

      return responseJson;
    } catch (err) {
      console.log("Some error", err);



      return false;
    }
  }
}
