import React from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileIcon } from "../../../../icons/AllIcons";

const FileCard = ({ file, index, onDelete }) => {
    console.log(file, "file")
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "6px",
        borderRadius: "8px",
        minHeight: "140px",
        maxHeight: "140px",
        minWidth: "160px",
        maxWidth: "160px",
        padding: "24px",
        border: "1px solid #E6E8EC",
      }}
    >
      <IconButton
        className="cursor-pointer"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          color: "#C44D56",
        }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
      <FileIcon
        className={"w-[46px] h-[46px]"}
        style={{ width: "46px", height: "46px" }}
      />
      <span
        style={{
          fontSize: "14px",
          color: "#999",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: "center",
        }}
      >
        {file?.name || file?.file_path?.split("/")?.pop()}
      </span>
    </div>
  );
};

export default FileCard;
