import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'

import { useGetAllLeadContractConditionsQuery, BLUE_PRINTS, useDeleteLeadContractConditionMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';

import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedLeadContractCondition } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';


import ViewLeadContractCondition from './ViewLeadContractCondition';
import AddUpdateLeadContractCondition from './AddUpdateLeadContractCondition.js';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import AddUpdateContractType from '../contract-condition-type/AddUpdateContractType';
import ContractType from '../contract-condition-type';
import HeaderButtons from '@jumbo/dz-components/header-buttons/HeaderButtons';


const LeadContractConditionList = () => {

  const dispatch = useDispatch();


  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteLeadContractCondition, responseDelete] = useDeleteLeadContractConditionMutation();



  const { data: res_get_all, } = useGetAllLeadContractConditionsQuery();
  const [showMode, setShowMode] = useState(false);
  const bluePrint = BLUE_PRINTS.contractCondition
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedLeadContractCondition(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLeadContractCondition(row));  }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLeadContractCondition(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedLeadContractCondition(row)); }
  bluePrint.handleDelete = (id) => { deleteLeadContractCondition(id); }
  const handleLeadConditionType = () => {
    // eslint-disable-next-line default-case
    //   console.log("handle", menuItem)

    const size = "xl";


      
        showDialog({
          // title: "Add Lead Condition Type",
          size,
   
          content: <ContractType />
        });
      }
  return (
    <>
    
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      {/* <HeaderButtons Add="Lead Condition Type" onClick={() => { handleLeadConditionType(); }} /> */}
      
      <JumboDemoCard
        title="Lead Contract Condition"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable

            btnTitleAdd="LeadContractCondition"
            data={res_get_all?.data?.contract_conditions}
            bluePrint={bluePrint}
            ViewModel={() => <ViewLeadContractCondition />}
            ModalContent={() => <AddUpdateLeadContractCondition onClose={hideDialog} fullscreen={true} />}
            showAddButton={true}
          
          />
         </div>
      </JumboDemoCard>
    </>
  );
}


export default LeadContractConditionList