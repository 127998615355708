import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'

import { useGetAllLeadServicesQuery, BLUE_PRINTS, useDeleteLeadServiceMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';

import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedLeadService } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';


import ViewLeadService from './ViewLeadService';
import AddUpdateLeadService from './AddUpdateLeadService.js';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';


const LeadServiceList = () => {

  const dispatch = useDispatch();


  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteLeadService, responseDelete] = useDeleteLeadServiceMutation();



  const { data: res_get_all, } = useGetAllLeadServicesQuery();
  const [showMode, setShowMode] = useState(false);
  const bluePrint = BLUE_PRINTS.leadService
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedLeadService(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLeadService(row));  }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLeadService(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedLeadService(row)); }
  bluePrint.handleDelete = (id) => { deleteLeadService(id); }
  return (
    <>
    
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title="Lead Services"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable

            btnTitleAdd="LeadService"
            data={res_get_all?.data?.lead_services}
            bluePrint={bluePrint}
            ViewModel={() => <ViewLeadService />}
            ModalContent={() => <AddUpdateLeadService onClose={hideDialog} fullscreen={true} />}
            showAddButton={true}
          
          />
         </div>
      </JumboDemoCard>
    </>
  );
}


export default LeadServiceList