import React, { useState, useEffect } from 'react';
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedCourseSelector } from 'app/rkt_query/SelectedStuffReducer';
// import ViewCourse from './../../../app/pages/course/ViewCourse';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { boxSX } from '../data-table/GlobalFunctions';
import { useNavigate } from 'react-router-dom';


export const handleGotoNavigate = (agentDetail, title, data, navigate) => {
    console.log("agent", data)
    var navigateTo;
    if (title === "Company") { navigateTo = "/companies" }
    else if (title === "Course") { navigateTo = "/courses" }
    else if (title === "Module") { navigateTo = "/modules" }
    else if (title === "Topic") { navigateTo = "/modules" }
    if (title === "Topic") {
        navigate(navigateTo, {
            state: {
                obj: '',
                relationalObjectTopic: data,
                relationalObject: data?.module?.course,
            }
        })
    }
    else {
        navigate(navigateTo, {
            state: {
                obj: '',
                relationalObject: data,
            }
        })
    }
}

// navigate("/companies", {
//     state: {
//       relationalObject: data,
//     },
//   });

const DZCard = ({ agentDetail, title, onClickCallback, data }) => {
    const navigate = useNavigate();
    return (
        <Card sx={{ overflow: 'visible', mt: 4, }}>
            <CardContent>
                <Stack direction={"row"} spacing={0.25} alignItems={'center'}>
                <Typography  ><b>{title}</b></Typography>
                
                    {/* <ApartmentOutlinedIcon /> */}
                    <div style={{ width: "80%" }}>
                        <h3 style={{ wordWrap: "break-word" }} ><b>{agentDetail}</b></h3>
                    </div>
                   
              
                    {/* <VisibilityOutlinedIcon sx={boxSX} onClick={() => { onClickCallback(); }} /> */}
                    {/* <Typography variant={"h5"}>{agentDetail}</Typography> */}
                </Stack>
            </CardContent>
        </Card>
    );
};
/* Todo agent detail prop */
export default DZCard;


// export const DZCardTranslatable = ({ agentDetail, title, selectid, onClickCallback, data }) => {
//     // const dispatch = useDispatch();
//     // dispatch(selectedCourseSelector(id))
//     const navigate = useNavigate();
//     const selectedLanguage = useSelector(selectedLanguageSelector);
//     return (

//         <Card sx={{ overflow: 'visible', mt: 4, mb: 5 }}>
//             <CardContent>
//                 <Stack direction={"row"} spacing={0.25} alignItems={'center'}>
//                     <div style={{ width: "80%", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                         {title === "Course" && <LocalLibraryOutlinedIcon />}
//                         {title === "Module" && <ViewModuleOutlinedIcon />}
//                         {title === "Topic" && <BorderColorOutlinedIcon />}
//                         <Typography style={{ wordWrap: "break-word" }} >{agentDetail ? agentDetail?.[selectedLanguage] : ""}</Typography>
//                     </div>
//                     <VisibilityOutlinedIcon sx={boxSX} onClick={() => { handleGotoNavigate(agentDetail, title, data, navigate); }} />
//                 </Stack>
//             </CardContent>
//         </Card>


//     )
// }



