import React from "react";
import Header from "./Header";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import {
  BLUE_PRINTS,
  useGetHotelQuery,
  useGetAllCompaniesQuery,
} from "app/rkt_query/storeApis";
import { useJumboTheme } from "@jumbo/hooks";
// import Events from "../../../shared/widgets/Events";
import { alpha } from "@mui/material/styles";
// import About from "./components/About";
// import Biography from "./components/Biography";
import HotelTab from "./HotelTab";

import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List } from "@mui/material";
import CourseItem from "./CourseItem";
import { useLocation, useNavigate } from "react-router-dom";
import { getAssetPath } from "app/utils/appHelpers";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import ListItemComponent from "./ListItemComponent";

const AddUpdateHotel = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();

  const { data: res_get } = useGetHotelQuery(location?.state?.user_id?.id);
  // console.log(res_get1,"asdasd");
  return (
    <JumboContentLayout
      // header={<Header user={res_get?.data?.user} />}
      sidebar={
        <HotelTab
          user={res_get?.data?.user}
          buddy_groups={res_get?.data?.buddy_groups}
        />
      }
      layoutOptions={{
        header: {
          sx: {
            mt: -4,
            mb: -7.25,
            mx: { xs: -4, lg: -6 },
            p: { xs: theme.spacing(6, 4, 11), lg: theme.spacing(6, 6, 11) },
            background: `#002447 url(${getAssetPath(
              `${ASSET_IMAGES}/profile-bg.jpg`,
              "1920x580"
            )})s no-repeat center`,
            backgroundSize: "cover",
            color: "common.white",
            position: "relative",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha(theme.palette.common.black, 0.65),
            },
          },
        },
        sidebar: {
          sx: {
            mr: 3.75,
            width: { xs: "100%", lg: "100%" },
            [theme.breakpoints.down("lg")]: {
              minHeight: 0,
              mr: 0,
              order: 2,
            },
          },
        },
        wrapper: {
          sx: {
            [theme.breakpoints.down("lg")]: {
              flexDirection: "column",
            },
          },
        },
        main: {
          sx: {
            [theme.breakpoints.down("lg")]: {
              minHeight: 0,
            },
          },
        },
      }}
    >
      {/* <JumboCardQuick
            title={"User Bar"}
        
            wrapperSx={{p: 0}}
            headerSx={{http://localhost:3000/admin-yaksport/sports
                borderBottom: 1,
                borderBottomColor: 'divider'
            }}
            sx={{mb: {xs: 3.75, lg: 0}}}
        >
                <ListItemComponent data={2} label={"Total Courses"}  />
    
        </JumboCardQuick> */}
    </JumboContentLayout>
  );
};

export default AddUpdateHotel;
