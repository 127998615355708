import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Zoom,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import LanguageSwitcher from "./../../../../dz-components/buttons/LanguageSwitcher";
import { CardActions } from "@mui/material";
import Close from "@mui/icons-material/Close";

const DialogBase = ({
  onClickCancel,
  showLanguageSwitcher = true,
  content,
  actions,
  title,
  subheader,
  contentProps,
  onClose,
  TransitionComponent,
  size = "md",
  disableDefaultClose,
  ...restProps
}) => {
  const { open, hideDialog } = useJumboDialog();
  console.log(showLanguageSwitcher, "showLanguageSwitcher");

  console.log("checking type", typeof onClickCancel);
 



  const handleClose = (event, reason) => {
 
    
    onClose();
    if (reason && reason == "backdropClick") return;
    else { 
     
      !disableDefaultClose && hideDialog();
    }
    // if (!disableDefaultClose)
    //     hideDialog();
  };
 
  return ( 
    <Dialog
      open={open}

      {...restProps}
      onClose={handleClose}
      TransitionComponent={TransitionComponent}
      fullWidth={true}
      maxWidth={size}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "20px 30px 0px 5px",
          position: "relative",
        }}
      >
        
        <div onClick={handleClose} style={{ cursor: 'pointer', position: "absolute", right: "0", top: "0", padding: '4px', background: '#eee', height: '26px', borderBottomLeftRadius: '10px' }}>
          <Close sx={{width: '18px', height: '16px'}} />
        </div>

        {title && (
          <CardHeader title={title} subheader={subheader} sx={{ pb: 0 }} />
        )}
        {showLanguageSwitcher && <LanguageSwitcher />}
      </div>
      <DialogContent {...contentProps}>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}

      {/* <div>
          <Typography>Footer Text</Typography>
        </div> */}
      {/* <CardActions disableSpacing>
            </CardActions> */}
      {/* <CardHeader title={title} subheader={subheader} sx={{ pb: 0 }} /> */}
    </Dialog>
  );
};

DialogBase.defaultProps = {
  onClose: () => {},
  TransitionComponent: Zoom,
  disableDefaultClose: false,
};

export default DialogBase;
