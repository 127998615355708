import { Button, TextField } from "@mui/material";
import {
  selectedPassengerSelector,
  setPassengerState,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddPassengerMutation,
  useGetAllRegionsQuery,
  useGetPassengerQuery,
  useUpdatePassengerMutation,
} from "app/rkt_query/storeApis";
import React, {  useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";

import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div/Div";
const ChangePassenger = ({ onClose,slug }) => {
  const dispatch = useDispatch();
console.log(slug);
  const { data: res_get } = useGetPassengerQuery(slug);

  const [updatePassenger, responseAdd] = useUpdatePassengerMutation();


  const [passengerState, setPassengerState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);

  const UpdatePassengerData = () => {
   
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append("previous_no_guests",passengerState.previous_no_guests);
    formData.append("no_guests",passengerState.no_guests);
   
     updatePassenger(formData);
  };
  const { error, resetCount } = useResponseError(responseAdd);
useEffect(() => {
 
setPassengerState(res_get?.data?.lead);
 
}, [res_get])


  return (
    <div>
   
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true && responseAdd 
        }
      />
      {passengerState ? (
        <div style={addUpdateDivStyleGlobal}>
        <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  fullWidth
                  label="Previous No Guests"
                  InputLabelProps={{ shrink: true }}
                  type='number'
                  variant="outlined"
                  noOfRows={1}
                  {...(error?.previous_no_guests && { error: true, helperText: error?.previous_no_guests })}

                  value={passengerState?.previous_no_guests}
                  // onChange={(e) => {
                  //   setPassengerState((x) => ({ ...x, previous_no_guests: e.target.value }));
                  // }}
                />
              </Div>
            </Grid>
        <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  fullWidth
                  label="Guests"
                  InputLabelProps={{ shrink: true }}
                  type='number'
                  variant="outlined"
                  noOfRows={1}
                  {...(error?.no_guests && { error: true, helperText: error?.no_guests })}

                  value={passengerState?.no_guests}
                  onChange={(e) => {
                    setPassengerState((x) => ({ ...x, no_guests: e.target.value }));
                  }}
                />
              </Div>
            </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setPassengerState(null));
              }}
            >
              Cancel
            </Button>
            
              <Button
                variant="contained"
                onClick={() => {
                  UpdatePassengerData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
          
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default ChangePassenger;
