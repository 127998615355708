import React from "react";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Button } from "@mui/material";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import DZTextValidation, {
  MultiDZTextValidation,
} from "../textboxes/DZTextValidation";
import DZDropdown, { DZSimpleDropDown } from "../dropdowns/DZDropdown";

const DZGenericView = ({
  obj,
  image,
  imagePath,
  checkImage = false,
  closeBtn = false,
  marginTop = "5%",
  grid = false,
}) => {
  const { hideDialog } = useJumboDialog();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        marginTop: marginTop,
      }}
    >
      {checkImage ? (
        <DZImageCircle
          image={image}
          link={imagePath}
          style={{
            height: 100,
            width: 100,
            borderRadius: 50,
            alignSelf: "center",
          }}
        />
      ) : (
        ""
      )}
      {grid ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 10,
          }}
        >
          {obj.map((item) =>
            item?.editor ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  backgroundColor: "rgb(245 241 248)",
                  borderRadius: 12,
                  paddingLeft: 12,
                  padding: 12,
                }}
              >
                <label style={{ fontSize: "1em", fontWeight: 500 }}>
                  {item.label}
                </label>
                <label style={{ fontSize: "1em" }}>
                  {" "}
                  {<div dangerouslySetInnerHTML={{ __html: item.data }} />}
                </label>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  backgroundColor: "rgb(245 241 248)",
                  borderRadius: 12,
                  paddingLeft: 12,
                  padding: 12,
                }}
              >
                <label style={{ fontSize: "1em", fontWeight: 700 }}>
                  {item.label}
                </label>
                <label style={{ fontSize: "1em" }}>{item.data}</label>
              </div>
            )
          )}
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {obj.map((item) =>
            item?.editor ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  backgroundColor: "rgb(245 241 248)",
                  borderRadius: 12,
                  paddingLeft: 12,
                  padding: 12,
                }}
              >
                <label style={{ fontSize: "1em", fontWeight: 500 }}>
                  {item.label}
                </label>
                <label style={{ fontSize: "1em" }}>
                  {" "}
                  {<div dangerouslySetInnerHTML={{ __html: item.data }} />}
                </label>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  backgroundColor: "rgb(245 241 248)",
                  borderRadius: 12,
                  paddingLeft: 12,
                  padding: 12,
                }}
              >
                <label style={{ fontSize: "1em", fontWeight: 700 }}>
                  {item.label}
                </label>
                <label style={{ fontSize: "1em" }}>{item.data}</label>
              </div>
            )
          )}
        </div>
      )}
      {closeBtn ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "flex-end",
            marginTop: "5%",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              hideDialog();
            }}
          >
            Close
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DZGenericView;

export const DZGenericTextField = ({
  obj,
  image,
  imagePath,
  checkImage = false,
  closeBtn = false,
  marginTop = "5%",
  grid = false,
  setStateData,
  state,
  onSubmit,
  setShowBackDrop,
}) => {
  const { hideDialog } = useJumboDialog();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        marginTop: marginTop,
      }}
    >
      {grid ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 10,
          }}
        >
          {obj.map((item) => (
            <div
              key={item.field}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                backgroundColor: "rgb(245 241 248)",
                borderRadius: 12,
                paddingLeft: 12,
                padding: 12,
              }}
            >
              {item?.field === "dropdown" ? (
                <DZDropdown
                  placeholder={item.label}
                  label={item.label}
                  value={state?.[item.field_name] || ""}
                  data={item?.array}
                  onChange={(e) => {
                    setStateData((x) => ({
                      ...x,
                      [item.field_name]: e.target.value,
                    }));
                  }}
                />
              ) : item?.field === "simple_dropdown" ? (
                <DZSimpleDropDown
                 placeholder={item.label}
                  label={item.label}
                  value={state?.[item.field_name] || ""}
                  data={item?.array}
                  onChange={(e) => {
                    setStateData((x) => ({
                      ...x,
                      [item.field_name]: e.target.value,
                    }));
                  }}
                />
              ): item?.field === "simple" ? (
                <>
                <label style={{ fontSize: "1em", fontWeight: 700 }}>
                  {item.label}
                </label>
                <label style={{ fontSize: "1em" }}>{item.data}</label>
                </>
              ) : (
                <DZTextValidation
                  label={item.label}
                  variant="outlined"
                  value={state?.[item.field_name] || ""}
                  onChange={(e) => {
                    setStateData((x) => ({
                      ...x,
                      [item.field_name]: e.target.value,
                    }));
                  }}
                  placeholder={item.label}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {obj.map((item) =>
            item?.editor ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  backgroundColor: "rgb(245 241 248)",
                  borderRadius: 12,
                  paddingLeft: 12,
                  padding: 12,
                }}
              >
                <label style={{ fontSize: "1em", fontWeight: 500 }}>
                  {item.label}
                </label>
                <label style={{ fontSize: "1em" }}>
                  {" "}
                  {<div dangerouslySetInnerHTML={{ __html: item.data }} />}
                </label>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  backgroundColor: "rgb(245 241 248)",
                  borderRadius: 12,
                  paddingLeft: 12,
                  padding: 12,
                }}
              >
                <label style={{ fontSize: "1em", fontWeight: 700 }}>
                  {item.label}
                </label>
                <label style={{ fontSize: "1em" }}>{item.data}</label>
              </div>
            )
          )}
        </div>
      )}
      {closeBtn ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "flex-end",
            marginTop: "5%",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              hideDialog();
            }}
          >
            Close
          </Button>
        </div>
      ) : (
        ""
      )}
      {onSubmit ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "flex-end",
            marginTop: "5%",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onSubmit();
              setShowBackDrop(true);
            }}
          >
            Update
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
