import React, { useEffect, useRef,useState } from 'react';
import { Jodit } from 'jodit';
import JoditReact from 'jodit-react';
import { Config } from 'constant';
const DzTextEditorMultilingual = ({ data, onChange }) => {
  const editor = useRef(null);
  const [editorConfig, setEditorConfig] = useState(null);
  useEffect(() => {
    const config = {
      readonly: false,
      autofocus: true,
      tabIndex: 1,
      height: 400,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      defaultActionOnPaste: 'insert_clear_html',
      placeholder: 'Write something awesome ...',
      beautyHTML: true,
      toolbarButtonSize: 'large',
      uploader: {
        insertImageAsBase64URI: true
      },
      controls: {
        font: {
          command: 'fontname',
          list: {
            "'Open Sans',sans-serif": 'Open Sans',
            'Helvetica,sans-serif': 'Helvetica',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
            'Consolas,monaco,monospace': 'Consolas'
          }
        }
      },
  
      extraButtons: ['Linkadd'],
    };
    setEditorConfig(config);
    linkButton();
  }, []); 
  const linkButton = () => {
    Jodit.defaultOptions.controls.Linkadd = {
      name: 'Linkadd',
      iconURL: "images/icons/Link.png",
      exec: async (editor) => {
        const url = prompt('Enter link URL:', 'https://');
        if (url !== null) {
          const title = prompt('Enter link title:', '');
          const linkText = `<a href="${url}" title="${title}">${title || url}</a>`;
          editor.selection.insertHTML(linkText);
        }
      },
    };
  }
  return (
    <React.Fragment>
     <div >
      <JoditReact
        ref={editor}
        height={400}
        value={data}
        config={editorConfig}
        onChange={(newContent) => {
          onChange(newContent)
        }}
      />
      </div>
    </React.Fragment>
  );
};
export default DzTextEditorMultilingual;