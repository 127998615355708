import React from 'react'
import {List,Link, ListItem, ListItemIcon, ListItemText, Typography,Tab} from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import styled from "@emotion/styled";

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));
const ListItemComponent = ({data,label,icon,color="text.secondary"}) => {
  return (
   <>
           <ListItem
                    sx={{
                        width: {xs: '100%', sm: '50%', xl: '33.33%'}
                    }}
                >
                   {icon&& <StyledListItemIcon>
                    {icon}
                       
                    </StyledListItemIcon>}
                    <ListItemText
                        primary={<Typography fontSize={"12px"} variant="h6" color={color} mb={.5}>
                            {label}</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">
                            {data}</Typography>}
                    />
                </ListItem>
   </>
  )
}

export default ListItemComponent