import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import {
 BLUE_PRINTS,
 useDeleteLeadServiceTypeMutation,
 useGetAllLeadServiceTypeQuery,
  // useGetAllCompaniesQuery
} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import {  setSelectedLeadServiceType } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useLocation, useNavigate } from 'react-router-dom';

// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { Box,  } from '@mui/material';
import { IconButton, Button, Typography } from '@mui/material';
import ViewLeadServiceType from './ViewLeadServiceType';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import DataTable from '@jumbo/dz-components/data-table/DataTable';
import AddUpdateLeadServiceType from './AddUpdateLeadServiceType';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';

const ServiceType = () => {


  const dispatch = useDispatch();
  const {  hideDialog } = useJumboDialog();
  const [deleteServiceType, responseDelete] = useDeleteLeadServiceTypeMutation();
  const { data: res_get_all, isLoading: isLoadingServiceTypes, error: errorServiceTypes } = useGetAllLeadServiceTypeQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS?.leadServiceType
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedLeadServiceType(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLeadServiceType(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLeadServiceType(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedLeadServiceType(row)); }
  bluePrint.handleDelete = (id) => { deleteServiceType(id); }

  return (
    <>
    
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"Lead Service Type"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="Lead Service Type"
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.lead_service_types}
              bluePrint={bluePrint}
              ViewModel={() => <ViewLeadServiceType />}
              ModalContent={() => <AddUpdateLeadServiceType onClose={hideDialog} />}
              showAddButton={false}
            />
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default ServiceType