import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Div from "@jumbo/shared/Div";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";

const SimpleBackdrop = ({ responseAdd, responseUpdate, isTrue }) => {
  return (
    <Div sx={{ textAlign: "center" }}>
      {(responseAdd?.isLoading || responseUpdate?.isLoading || isTrue) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={responseAdd?.isLoading || responseUpdate?.isLoading || isTrue}
          onClick={() => console.log("clicked bakc")}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Div>
  );
};
export default SimpleBackdrop;
