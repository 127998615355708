import React from "react";
import Div from "@jumbo/shared/Div";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import useChatApp from "../../../hooks/useChatApp";
import FilePreview from "./FilePriview";

const ReceivedMessageContent = ({ message }) => {
  // const {activeConversation} = useChatApp();
  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleString(); // Formats the date and time according to the user's locale
  }
  const formattedDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };
  console.log(message, "message");
  return (
    <>
    
        <Div
          sx={{
            display: "flex",
            alignItems: "flex-start",
            mb: 2,
            px: 3,
            "& .Message-item": {
              marginTop: "2px",
            },
          }}
        >
            {(message?.message != null && message?.message)  && (
          <React.Fragment>
            <Avatar
              alt={message?.lead_user?.first_name}
              src={message?.lead_user?.first_name}
              sx={{ width: 40, height: 40, mr: 2 }}
            />
            <div className="Message-root">
                <>
                  <Typography
                    variant={"body1"}
                    color={"text.secondary"}
                    fontSize={"smaller"}
                    mb={0.5}
                  >
                    {message?.lead_user?.first_name} -{" "}
                    {formattedDate(formatDateTime(message?.created_at))}
                  </Typography>
                  <div className="Message-item">
                    <Paper
                      elevation={0}
                      sx={{
                        p: (theme) => theme.spacing(1.5, 2),
                        bgcolor: "primary.main",
                        color: "common.white",
                      }}
                    > 
                      <Typography variant={"body1"}>
                        {message?.message}
                      </Typography>
                    </Paper>
                  </div>
                </>
            </div>

          </React.Fragment> 
              )}
              </Div>
            <Div
          sx={{
            display: "flex",
            alignItems: "flex-column",
            mb: 2,
            px: 3,
            "& .Message-item": {
              marginTop: "2px",
            },
          }}
          style={{display:"flex",}}
        >
              {message?.attachments?.map((data, index) => {
                return (
                  <>
           
                  <Avatar
                  alt={message?.lead_user?.first_name}
                  src={message?.lead_user?.first_name}
                  sx={{ width: 40, height: 40, mr: 2 }}
                />
                <FilePreview key={index} data={data} index={index} />
                </>
              );
              })}
        </Div>
      
    </>
  );
};

export default ReceivedMessageContent;
