import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedLeadSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetLeadQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewLead = ({ selectId }) => {

    const selectedLead = useSelector(selectedLeadSelector);
    const [LeadId, setLeadId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingLead, error: errorLead } = useGetLeadQuery(LeadId);
    const leadType = res_get?.data?.lead?.lead_type;
    
    const selectedLanguage = useSelector(selectedLanguageSelector);

    useEffect(() => {
    
        setLeadId(selectedLead ? selectedLead?.id : selectId)
    }, [])
    const LeadArray =
        [  
            { label: 'User Name', data: res_get?.data?.lead?.lead_user?.first_name+' '+res_get?.data?.lead?.lead_user?.last_name },
            { label: 'E-mail', data: res_get?.data?.lead?.lead_user?.email },
            { label: 'Telephone', data: res_get?.data?.lead?.lead_user?.mobile },
            { label: 'Lead type', data: res_get?.data?.lead?.lead_type },
            { label: 'Sport', data: res_get?.data?.lead?.sport?.name[selectedLanguage] },
            { label: 'Airport', data: res_get?.data?.lead?.airport?.name },
            { label: 'Hotel', data: res_get?.data?.lead?.hotel?.name },
            { label: 'Club Name', data: res_get?.data?.lead?.club_name },
            { label: 'Country', data: res_get?.data?.lead?.country?.name[selectedLanguage] },
            { label: 'City', data: res_get?.data?.lead?.city?.name },
            { label: 'Guests', data: res_get?.data?.lead?.no_guests },
            { label: 'Budget', data: res_get?.data?.lead?.budget },
            { label: 'From Date', data: res_get?.data?.lead?.from_date }, 
            { label: 'To Date', data: res_get?.data?.lead?.to_date }, 
            { label: 'Description', data: res_get?.data?.lead?.comments[selectedLanguage],editor:true},
          


        ]
        const filteredLeadArray = leadType === 'direct'
    ? LeadArray 
    : LeadArray.filter(item => item.label !== 'Hotel' && item.label !== 'Airport');
 
    return (
        <div>

            {errorLead ? <div>{JSON.stringify(errorLead)}</div>
                    : isLoadingLead ? <div>Loading Single Lead.....</div>
                    : res_get?.data?.lead ?
                        <DZGenericView obj={filteredLeadArray} closeBtn={true} />
                        : <div>Bad Code</div>}

        </div>
    )
}




export default ViewLead

