import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import {
  selectedAdminSelector,
  setSelectedAdmin,
} from "app/rkt_query/SelectedStuffReducer";
import {
  BLUE_PRINTS,
  useAddAdminMutation,
  useGetAdminQuery,
  useGetAllAdminsQuery,
  useGetAllRegionsQuery,
  useUpdateAdminMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { DZSimpleDropDown } from "@jumbo/dz-components/dropdowns/DZDropdown";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import Div from "@jumbo/shared/Div";
import { DzNoBackgroundLabel } from "@jumbo/dz-components/labels/DZLabel";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
const AddUpdateAdmin = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedAdmin = useSelector(selectedAdminSelector);
  const { fnShowSnackBar } = useSnackBarManager();

  const { data: res_get } = useGetAdminQuery(selectedAdmin?.id);
  const { data: res_get_roles } = useGetAllAdminsQuery();
  const { data: res_get_all_regions } = useGetAllRegionsQuery();
  const [updateAdmin, responseUpdate] = useUpdateAdminMutation();
  const [addAdmin, responseAdd] = useAddAdminMutation();
  const bluePrint = BLUE_PRINTS.admin;
  const [adminState, setAdminState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  const addUpdateAdminData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.admin?.id);
    formData.append("name", adminState.name);
    formData.append("role_name", adminState.role_name);
    formData.append("email", adminState.email);
    formData.append("password", adminState.password);
    formData.append("regions_id", JSON.stringify(adminState.regions_id));

    const res = action === "add" ? await addAdmin(formData) : await updateAdmin(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };
  const regionIds = res_get?.data?.selectedRegions?.map((region) =>
    parseInt(region?.region_id, 10)
  );
  useEffect(() => {
    setAdminState(
      selectedAdmin
        ? {
            ...res_get?.data?.admin,
            role_name: res_get?.data?.admin?.role?.role_name?.name,
            regions_id: regionIds ?? [],
          }
        : {
            ...bluePrint,
            role_name: res_get?.data?.admin?.role?.role_name?.name,
          }
    );
  }, [res_get]);
  const roles = res_get_roles?.data?.roles?.map((role) => role?.name);
  const regions = res_get_all_regions?.data?.regions?.map((region) => region);
  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {adminState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
          }}
        >
          <DZSimpleDropDown
            value={adminState?.role_name || ""}
            data={roles}
            name="role_name"
            placeholder="Select Role"
            onChange={(e) => {
              setAdminState((prevState) => ({
                ...prevState,
                role_name: e.target.value,
              }));
            }}
          />

          {/* region */}
          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DzNoBackgroundLabel title={"Regions"} />
              </Div>
            </Grid>
            {regions?.map((region) => (
              <Grid item xs={5} md={2} lg={2} key={region?.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={adminState?.regions_id?.includes(region?.id)}
                      name={region?.language}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setAdminState((prevState) => ({
                          ...prevState,
                          regions_id: checked
                            ? [...prevState?.regions_id, region?.id]
                            : prevState?.regions_id.filter(
                                (id) => id !== region?.id
                              ),
                        }));
                      }}
                    />
                  }
                  label={region?.language}
                />
              </Grid>
            ))}
          </Grid>

          <DZTextValidation
            label="Name"
            variant="outlined"
            value={adminState?.name}
            onChange={(e) => {
              setAdminState((x) => ({ ...x, name: e.target.value }));
            }}
            error={error?.name}
          />

          <DZTextValidation
            label="Email"
            variant="outlined"
            value={adminState?.email}
            onChange={(e) => {
              setAdminState((x) => ({ ...x, email: e.target.value }));
            }}
            error={error?.email}
          />

          <DZTextValidation
            label="Password"
            variant="outlined"
            value={adminState?.password}
            onChange={(e) => {
              setAdminState((x) => ({ ...x, password: e.target.value }));
            }}
            error={error?.password}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 10,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            {selectedAdmin ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateAdminData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateAdminData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedAdmin(null));
                }}
              >
                Add
              </Button>
            )}
            {/* dispatch(setSelectedAdmin(null)); */}
          </div>
          {/* <div style={{ width: '10%' }}>{JSON.stringify(adminState)}</div> */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
      {/* {!selectedAdmin &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}
    </div>
  );
};

export default AddUpdateAdmin;
