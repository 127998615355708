import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedHotelSportSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetHotelSportQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewHotelSport = ({ selectId }) => {

    const selectedHotelSport = useSelector(selectedHotelSportSelector);
    const [HotelSportId, setHotelSportId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingHotelSport, error: errorHotelSport } = useGetHotelSportQuery(HotelSportId);
  
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    useEffect(() => {
        
        setHotelSportId(selectedHotelSport ? selectedHotelSport?.id : selectId)
    }, [])
    const sportArray =
        [
            { label: 'Name', data: res_get?.data?.sport?.name[selectedLanguage] },

        ]
  
    return (
        <div>

            {errorHotelSport ? <div>{JSON.stringify(errorHotelSport)}</div>
                : isLoadingHotelSport ? <div>Loading Single HotelSport.....</div>
                    : res_get?.data?.sport ?
                        <DZGenericView obj={sportArray} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewHotelSport

