import React from 'react'
import { useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';

export default function DZLabel({ title, text, children, translatable,mt="5%",fontSize="1.5em", style }) {
  // console.log(title, text, children)
  const selectedLanguage = useSelector(selectedLanguageSelector);
  return (

    //  style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}
    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop:mt  }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
        <div >
          <label style={{ fontSize: "1.5em", fontWeight: 500 }}>{title}</label>
          <label style={{ fontSize: "1em" }}>{children}</label>
        </div>
      </div>
    </div>


  )
}
export const DZLabelDescription = ({ title, text, children, translatable, style }) => {


  return (

    //  style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}

    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, }}>

      <label style={{ fontSize: "1em", fontWeight: 500 }}>{title}</label>
      <label style={{ fontSize: "1em" }}>{children}</label>
    </div>




  )
}
export const DZDataLabel = ({ title, text, children, }) => {

  // console.log(title, text, children)
  const selectedLanguage = useSelector(selectedLanguageSelector);
  return (


    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
      <label style={{ fontSize: "1em", fontWeight: 500 }}>{title}</label>
      <label style={{ fontSize: "1em" }}>{children}</label>
    </div>


  )
}
export const DZTranslatable = ({ title, text, children, }) => {

  console.log(title, text, children)
  const selectedLanguage = useSelector(selectedLanguageSelector);
  return (



    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
      <label style={{ fontSize: "1em", fontWeight: 500 }}>{title}</label>
      <label style={{ fontSize: "1em" }}>{children ? children?.[selectedLanguage] : ""}</label>
    </div>


  )
}
export const DzNoBackgroundTranslatable = ({ title, text, children, }) => {

  //console.log(title, text, children)
  const selectedLanguage = useSelector(selectedLanguageSelector);
  return (



    <div >
      <label style={{ fontSize: "1.5em", fontWeight: 500 }}>{title + ' :  '}</label>
      <label style={{ fontSize: "1.2em", fontWeight: 500 }} >{children ? children?.[selectedLanguage] : ""}</label>
    </div>


  )
}
export const DzNoBackgroundLabel = ({ title, text, children, }) => {

  //console.log(title, text, children)

  return (



    <div >
      <label style={{ fontSize: "1.5em", fontWeight: 500 }}>{title + ' :  '}</label>
    
    </div>


  )
}
export const DzNoBackgroundTranslatableModule = ({ title = "Module", text, children, }) => {

  //console.log(title, text, children)
  const selectedLanguage = useSelector(selectedLanguageSelector);
  return (



    <div >
      <label style={{ fontSize: "1.5em", fontWeight: 200 }}>{title + ' :  '}</label>
      <label style={{ fontSize: "1.2em", fontWeight: 200 }} >{children ? children?.[selectedLanguage] : ""}</label>
    </div>


  )
}
{/* <label style={style}>{translatable ? children?.[selectedLanguage] ?? "" : children}</label> */ }