import React from 'react';
import Avatar from "@mui/material/Avatar";
import {Button} from "@mui/material";
// import ContentHeader from "../../../layouts/shared/headers/ContentHeader";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
// import {ASSET_AVATARS} from "../../../utils/constants/paths";
// import {getAssetPath} from "../../../utils/appHelpers";
import { Config } from 'constant';
import ContentHeader from '../../layouts/shared/headers/ContentHeader/index';

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    padding: theme.spacing(0, 1),

    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& .MuiTouchRipple-root': {
        display: 'none'
    }

}));

const Item = styled("div")({
    textAlign: 'center',
});


const Header = ({user}) => {
    return (
        <ContentHeader
            avatar={
                <Avatar
                    sx={{width: 72, height: 72}}
                    alt={user?.name}
                    src={Config.AWSUserLargeImg+user?.avatar}
                />
            }
            title={user?.name}
           
            sx={{
                position: 'relative',
                zIndex: 1,

                '& .MuiCardHeader-action': {
                    alignSelf: 'center'
                }
            }}
        />
    );
};

export default Header;
