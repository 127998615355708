import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
import { selectedLeadServiceTypeSelector,  } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetLeadServiceTypeQuery,  } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';


const ViewLeadServiceType = ({ selectId }) => {

    const selectedServiceType = useSelector(selectedLeadServiceTypeSelector);
    const [serviceTypeId, setServiceTypeId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingServiceType, error: errorServiceType } = useGetLeadServiceTypeQuery(serviceTypeId);
  
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    useEffect(() => {
        
        setServiceTypeId(selectedServiceType ? selectedServiceType?.id : selectId)
    }, [])
    const serviceTypeArray =
        [
            { label: 'Name', data: res_get?.data?.lead_service_types?.name[selectedLanguage] },

        ]
        console.log("object", res_get?.data?.lead_service_types);
  
    return (
        <div>

            {errorServiceType ? <div>{JSON.stringify(errorServiceType)}</div>
                : isLoadingServiceType ? <div>Loading Single Lead Service Type.....</div>
                    : res_get?.data?.lead_service_types ?
                        <DZGenericView obj={serviceTypeArray} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewLeadServiceType

