import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
import { selectedContractConditionTypeSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useGetContractConditionTypeQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';


const ViewContractType = ({ selectId }) => {

    const selectedContractType = useSelector(selectedContractConditionTypeSelector);
    const [contractTypeId, setContractTypeId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingContractType, error: errorContractType } = useGetContractConditionTypeQuery(contractTypeId);
  
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    useEffect(() => {
        
        setContractTypeId(selectedContractType ? selectedContractType?.id : selectId)
    }, [])
    const ContractTypeArray =
        [
            { label: 'Name', data: res_get?.data?.contract_condition_types?.name[selectedLanguage] },

        ]
        console.log("object", res_get?.data?.contract_condition_types);
  
    return (
        <div>

            {errorContractType ? <div>{JSON.stringify(errorContractType)}</div>
                : isLoadingContractType ? <div>Loading Single Lead Contract Type.....</div>
                    : res_get?.data?.contract_condition_types ?
                        <DZGenericView obj={ContractTypeArray} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewContractType;

