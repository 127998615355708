import {
  hideSnackBarMessage,
  setSnackBarMessage,
  setSnackBarStatuses,
  snackBarMessageSelector,
  snackBarStatusSelector,
} from "app/rkt_query/SnackBarReducer";
import { useDispatch, useSelector } from "react-redux";

export function useSnackBarManager() {
  const dispatch = useDispatch();
  const isShowSnackBar = useSelector(snackBarMessageSelector);
  const isErrorTrue = useSelector(snackBarStatusSelector);
  const fnShowSnackBar = (message, isError) => {
    if (message) {
      dispatch(setSnackBarMessage(message));
      if (isError) {
        dispatch(setSnackBarStatuses("isError"));
      }
    }
    setTimeout(() => {
      dispatch(hideSnackBarMessage());
    }, 3000);
  };

  const fnHideSnackBar = () => {
    dispatch(hideSnackBarMessage());
  };

  return {
    isShowSnackBar,
    fnShowSnackBar,
    fnHideSnackBar,
    isErrorTrue,
  };
}
