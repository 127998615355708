
import { selectedLeadServiceSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useGetLeadServiceQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
const ViewLeadService = ({ selectId }) => {

    const selectedLeadService = useSelector(selectedLeadServiceSelector);
    console.log("object",selectedLeadService);
    const [LeadServiceId, setLeadServiceId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingLeadService, error: errorLeadService } = useGetLeadServiceQuery(LeadServiceId);
    // const ViewLeadService = ({showId}) => {
  console.log(LeadServiceId);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
   
        setLeadServiceId(selectedLeadService ? selectedLeadService?.id : selectId)
    }, [])
    const leadServiceArray =
        [
            { label: 'Type', data: res_get?.data?.lead_service?.type },
            { label: 'Title', data: res_get?.data?.lead_service?.title?.[selectedLanguage] },
       

        ]

    return (
        <div>

            {errorLeadService ? <div>{JSON.stringify(errorLeadService)}</div>
                : isLoadingLeadService ? <div>Loading Single LeadService.....</div>
                    : res_get?.data?.lead_service ?
                        <DZGenericView obj={leadServiceArray}  closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewLeadService

