import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import {
 BLUE_PRINTS, useGetSportQuery, useDeleteSportMutation, useGetAllSportsQuery,
  // useGetAllCompaniesQuery
} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Sport from './AddUpdateSport';
import { setSelectedSport } from 'app/rkt_query/SelectedStuffReducer';
import ViewSport from './ViewSport';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';

// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { Box } from '@mui/material';
import { IconButton, Button, Typography } from '@mui/material';



const SportList = () => {

  const location = useLocation();
  const navigate = useNavigate();



  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteSport, responseDelete] = useDeleteSportMutation();
  const { data: res_get_all, isLoading: isLoadingSports, error: errorSports } = useGetAllSportsQuery();
  // const { data: res_get_all_companies, isLoading: isLoadingCompanies, error: errorCompanies } = useGetAllCompaniesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.sport
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedSport(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedSport(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedSport(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedSport(row)); }
  bluePrint.handleDelete = (id) => { deleteSport(id); }

 




  return (
    <>
    
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
 

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"Sports"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="Sport"
              relationalObject={location?.state?.relationalObject}
              // listData={res_get_all_companies?.data?.companies}
              companyName={location?.state?.company}
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.sports}
              bluePrint={bluePrint}
              ViewModel={() => <ViewSport />}
              ModalContent={() => <Sport onClose={hideDialog} />}
           
              showAddButton={false}
           
            />
          
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default SportList