import { Box, Button, TextField, Fab } from "@mui/material";
import {
  selectedLeadContractConditionSelector,
  setSelectedLeadContractCondition,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddLeadContractConditionMutation,
  useGetLeadContractConditionQuery,
  useUpdateLeadContractConditionMutation,
  useGetAllCountriesQuery,
  useGetAllContractConditionTypeQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";

import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

// import { AddCircle } from "@material-ui/icons";

const AddUpdateLeadContractCondition = ({
  onClose,
  learning_journey_id,
  selectedId,
}) => {
  const { fnShowSnackBar } = useSnackBarManager();

  // const chatbot_types = ['scale', 'note', 'buddy'];
  const { hideDialog } = useJumboDialog();
  const types = ["Important Info", "Meal Times", "Workout", "Other"];
  const {
    data: res_get_all,
    isLoading: isLoadingContractTypes,
    error: errorContractTypes,
  } = useGetAllContractConditionTypeQuery();
  const dispatch = useDispatch();

  const selectedLeadContractCondition = useSelector(
    selectedLeadContractConditionSelector
  );

  const { data: res_get } = useGetLeadContractConditionQuery(
    selectedLeadContractCondition?.id
  );

  const [updateLeadContractCondition, responseUpdate] =
    useUpdateLeadContractConditionMutation();
  const [addLeadContractCondition, responseAdd] =
    useAddLeadContractConditionMutation();

  const bluePrint = BLUE_PRINTS.contractCondition;

  const [leadContractConditionState, setLeadContractConditionState] =
    useState(null);
  const [formErrors, setFormErrors] = useState({});

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setLeadContractConditionState(
      selectedLeadContractCondition
        ? res_get?.data?.contract_condition
        : {
            ...bluePrint,
            contract_condition_type_id:
              res_get?.data?.contract_condition?.contract_condition_type_id ??
              "",
          }
    );
  }, [res_get]);

  const validationRules = {
    contract_condition_type_id: {
      required: true,
    },
    title: {
      required: true,
    },
  };

  const validateForm = () => {
    const errors = {};

    Object.keys(validationRules).forEach((field) => {
      const rules = validationRules[field];
      if (rules.required && !leadContractConditionState?.[field]) {
        errors[field] = "This field is required";
      }
      if (!leadContractConditionState.title?.en) {
        errors["title"] = "This field is required";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length == 0;
  };

  const addUpdateLeadContractConditionData = async (action) => {
    const formData = new FormData();
    formData.append("id", res_get?.data?.contract_condition?.id);

    formData.append("title", JSON.stringify(leadContractConditionState.title));

    formData.append(
      "contract_condition_type_id",
      leadContractConditionState.contract_condition_type_id
    );

    if (validateForm()) {
      const res =
        action === "add"
          ? await addLeadContractCondition(formData)
          : await updateLeadContractCondition(formData);
      if (res?.data?.message) {
        // fnShowSnackBar(res?.data?.message || "Done successfully");
      } else {
        fnShowSnackBar(res?.error?.data?.message,"error");
      }
    } else {
      console.error("Form is invalid. Please fill out all required fields.");
    }
  };
  const condition_types = res_get_all?.data?.contract_condition_types?.map(
    (contract_condition_type) =>
      BLUE_PRINTS.contractConditionType.translate(
        contract_condition_type,
        selectedLanguage
      )
  );

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      {leadContractConditionState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={formErrors?.contract_condition_type_id}
                value={
                  leadContractConditionState?.contract_condition_type_id || ""
                }
                data={condition_types}
                onChange={(e) => {
                  setLeadContractConditionState((x) => ({
                    ...x,
                    contract_condition_type_id: e.target.value,
                  }));
                }}
                placeholder="Select Condition Type"
              />
            </Div>
          </Grid>

          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <MultiDZTextValidation
                  error={formErrors?.title}
                  label="Title"
                  variant="outlined"
                  value={leadContractConditionState?.title?.[selectedLanguage]}
                  onChange={(e) => {
                    setLeadContractConditionState((x) => ({
                      ...x,
                      title: { ...x.title, [selectedLanguage]: e.target.value },
                    }));
                  }}
                  placeholder="Title"
                />
              </Div>
            </Grid>
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedLeadContractCondition(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.contract_condition?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadContractConditionData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLeadContractCondition(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadContractConditionData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLeadContractCondition(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedLeadContractCondition(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateLeadContractCondition;
