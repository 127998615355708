import React, { useState } from "react";
import { TextField } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import InputAdornment from "@mui/material/InputAdornment";
const DZTextValidation = ({
  error,
  type = "text",
  inputProps = "",
  sx = "",
  errorValue = "",
  label = "",
  noOfRows = 1,
  maxRows = 3,
  name = "",
  variant = "outlined",
  value = "",
  onChange = (e) => { },
}) => {
  return (
    <TextField
      fullWidth
      id="outlined-basic"
      sx={sx || ""}
      inputProps={{ inputProps }}
      {...(error && { error: true, helperText: error })}
      label={label}
      variant={variant}
      value={value}
      onChange={onChange}
      multiline
      maxRows={maxRows}
      minRows={noOfRows}
    />
    //  error={redErr} helperText={redErr? 'Required*' : ''}
  );
};

export default DZTextValidation;

export const MultiDZTextValidation = ({
  error,
  type = "text",
  inputProps = "",
  sx = "",
  errorValue = "",
  label = "",
  noOfRows = 1,
  name = "",
  variant = "outlined",
  value = "",
  onChange = (e) => { },
}) => {
  return (
    <TextField
      fullWidth
      id="outlined-basic"
      sx={sx}
      inputProps={{ inputProps }}
      {...(error && { error: true, helperText: error })}
      label={label}
      variant={variant}
      value={value}
      onChange={onChange}
      multiline
      maxRows={3}
      minRows={noOfRows}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <TranslateIcon />
          </InputAdornment>
        ),
      }}
    />
    //  error={redErr} helperText={redErr? 'Required*' : ''}
  );
};
// export default MultiDZTextValidation

export const DZTextPasswordValidation = ({
  error,
  errorValue = "",
  label = "",
  noOfRows = 1,
  variant = "outlined",
  value = "",
  onChange = (e) => { },
}) => {
  return (
    <TextField
      id="outlined-basic"
      {...(error && { error: true, helperText: error })}
      label={label}
      variant={variant}
      value={value}
      onChange={onChange}
      multiline
      maxRows={3}
      minRows={noOfRows}
    />
    //  error={redErr} helperText={redErr? 'Required*' : ''}
  );
};
export const DZTextFieldDisable = ({
  error,
  type = "text",
  inputProps = "",
  sx = "",
  errorValue = "",
  label = "",
  noOfRows = 1,
  name = "",
  variant = "outlined",
  value = "",
  onChange = (e) => { },
}) => {
  return (
    <TextField
      fullWidth
      id="outlined-basic"
      disabled
      sx={sx}
      inputProps={{ inputProps }}
      label={label}
      variant={variant}
      value={value}
      onChange={onChange}
      multiline
      maxRows={3}
      minRows={noOfRows}
    />

    //  error={redErr} helperText={redErr? 'Required*' : ''}
  );
};
