import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Typography } from "@mui/material";
import DndWrapper from "./DndWrapper";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import code from "../components/demo-code/dz-preview.txt";
import {
  useAddHotelSportImageMutation,
  useDeleteHotelSportImageMutation,
  useGetHotelSportImageQuery,
} from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedHotelSelector,
  setSelectedHotel,
} from "app/rkt_query/SelectedStuffReducer";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";

import DeleteIcon from "@mui/icons-material/Delete";

// importing button css file
import "./IconHover.css";
import { Config } from "constant";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZDeleteDialog from "@jumbo/dz-components/dialog/DZDeleteDialog";
import CloseIcon from "@mui/icons-material/Close";
import MultiImageUpload from "app/DZcomponents/MultiImageUpload/MultiImageUpload";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const DzPreviews = ({ onClose, hotelName }) => {
  const selectedHotel = useSelector(selectedHotelSelector);
  const [open, setOpen] = useState(false);
  const [hotelImageId, setHotelImageId] = useState(null);
  const [files, setFiles] = useState([]);
  const [addHotelSportImage, responseAdd] = useAddHotelSportImageMutation();
  const [deleteHotelImage, responseDeleteImage] =
    useDeleteHotelSportImageMutation();
  const { fnShowSnackBar } = useSnackBarManager();
  const dispatch = useDispatch();
  const { data: res_get_hotel_Sport_images, refetch } =
    useGetHotelSportImageQuery({ id: selectedHotel?.id, sports_id: "" });
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });
  const handleDeleteImage = (index) => {
    // Create a copy of the files array and remove the selected image
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
      <div className="delete__icon">
        <DeleteIcon onClick={() => handleDeleteImage(index)} />
      </div>
    </div>
  ));

  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const uploadImages = async () => {
    setShowBackDrop(true);
    if(files.length === 0){
      fnShowSnackBar("Please select images","error");
      return
    }
    const formData = new FormData();

    formData.append("hotelImageId", selectedHotel?.id);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    const res = await addHotelSportImage(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }

    setFiles([]);
    refetch();
  };

  const deleteImage = () => {
    deleteHotelImage(hotelImageId)
      .unwrap()
      .then((payload) => {
        refetch();
      })
      .catch((error) => {});
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (id) => {
    setOpen(true);
    setHotelImageId(id);
  };

  return (
    <>
      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} />}
      <Snackbar
        responseAdd={responseAdd?.isSuccess === true ? responseAdd : ""}
      />

      {responseDeleteImage?.isSuccess ? (
        <NoHideSnackbar
          responseAdd={
            responseDeleteImage?.isSuccess === true ? responseDeleteImage : ""
          }
        />
      ) : (
        ""
      )}

      <MultiImageUpload
        onClose={onClose}
        imgfiles={files}
        setimgfiles={setFiles}
        uploadImagesfiles={uploadImages}
        hotelName={hotelName}
      />

      <JumboDemoCard
        title={"Hotel Images"}
        demoCode={code}
        wrapperSx={{ pt: 0, backgroundColor: "background.paper" }}
        sx={{ marginTop: "40px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {res_get_hotel_Sport_images?.data?.hotel_images?.map((hotel) => {
            return (
              <div style={{ position: "relative" }}>
                <img
                  style={{
                    width: "200px",
                    height: "190px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                  src={Config.digitalOceanLinkHotelSmallImg + hotel?.image}
                  alt={hotel?.image}
                  onError={(e) => {
                    e.target.src = "Image_not_available.png";
                  }}
                />
                <div className="delete__icon">
                  <DZDeleteDialog
                    title={"Are you sure about deleting this Image?"}
                    description={
                      " You won't be able to recover this image later"
                    }
                    openBox={open}
                    handleClose={() => handleClose()}
                    handleDeleteFunctionCall={() => deleteImage(hotel.id)}
                  />
                  <DeleteIcon
                    onClick={() => {
                      handleClickOpen(hotel.id);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </JumboDemoCard>
    </>
  );
};

export default DzPreviews;
