import { Button, TextField, Fab, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { MultiDZTextValidation } from "@jumbo/dz-components/textboxes/DZTextValidation";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import {
  selectedLeadServiceTypeSelector,
  setSelectedLeadServiceType,
} from "app/rkt_query/SelectedStuffReducer";
import {
  BLUE_PRINTS,
  useAddLeadServiceTypeMutation,
  useGetLeadServiceTypeQuery,
  useUpdateLeadServiceTypeMutation,
} from "app/rkt_query/storeApis";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateLeadServiceType = ({ onClose }) => {
  const { fnShowSnackBar } = useSnackBarManager();

  const dispatch = useDispatch();
  const selectedServiceType = useSelector(selectedLeadServiceTypeSelector);
  const {
    data: res_get,
    isLoading: isLoadingServiceType,
    error: errorServiceType,
  } = useGetLeadServiceTypeQuery(selectedServiceType?.id);

  const [updateLeadServiceType, responseUpdate] =
    useUpdateLeadServiceTypeMutation();
  const [addServiceType, responseAdd] = useAddLeadServiceTypeMutation();
  const bluePrint = BLUE_PRINTS?.leadServiceType;

  const [disable, setDisabled] = useState([true]);

  const [serviceTypeState, setServiceTypeState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);

  const addUpdateServiceTypeData = async (action) => {
    const formData = new FormData();
    formData.append("id", res_get?.data?.lead_service_type?.id);
    formData.append("name", JSON.stringify(serviceTypeState?.name));
    const res =
      action === "add"
        ? await addServiceType(formData)
        : await updateLeadServiceType(formData);

    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setServiceTypeState(
      selectedServiceType ? res_get?.data?.lead_service_type : bluePrint
    );
    setDisabled(selectedServiceType ? false : true);
  }, [res_get]);

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {serviceTypeState ? (
        <div style={addUpdateDivStyleGlobal}>
          <MultiDZTextValidation
            error={error?.[0] && "The Field is required"}
            label="Name"
            variant="outlined"
            value={serviceTypeState?.name[selectedLanguage]}
            onChange={(e) => {
              setServiceTypeState((x) => ({
                ...x,
                name: { ...x.name, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Name"
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedLeadServiceType(null));
              }}
            >
              Cancel
            </Button>
            {selectedServiceType?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateServiceTypeData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateServiceTypeData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLeadServiceType(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateLeadServiceType;
