import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedLeadContractSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetLeadContractQuery,} from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
import DZLabel, { DZLabelDescription } from '../../../@jumbo/dz-components/labels/DZLabel';
import { Button, Card, CardContent } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';


const ViewLeadContract = ({ selectId }) => {

    const selectedLead = useSelector(selectedLeadContractSelector);
    const [LeadId, setLeadId] = useState(selectId);
    const { data: res_get_lead_contract, isLoading: isLoadingLead, error: errorLead } = useGetLeadContractQuery(LeadId);
    const { showDialog, hideDialog } = useJumboDialog();
    const selectedLanguage = useSelector(selectedLanguageSelector);
    
    useEffect(() => {
        
        setLeadId(selectedLead ? selectedLead?.id : selectId)
    }, [])
    // console.log(res_get_leadcontract,"fdgdsfgds");
    const LeadArray =
    [  
        { label: 'Lead Type', data: res_get_lead_contract?.data?.lead_contract?.lead?.lead_type },
        { label: 'Lead Contract Status', data: res_get_lead_contract?.data?.lead_contract?.status },
        { label: 'Hotel', data: res_get_lead_contract?.data?.lead_contract?.hotel?.name },
        { label: 'Airport', data: res_get_lead_contract?.data?.lead_contract?.airport?.name },
        { label: 'Expiry Date', data: res_get_lead_contract?.data?.lead_contract?.expired_at },
        { label: 'From Date', data: res_get_lead_contract?.data?.lead_contract?.from_date },
        { label: 'To Date', data: res_get_lead_contract?.data?.lead_contract?.to_date },
        { label: 'Flight Time', data: res_get_lead_contract?.data?.lead_contract?.flight_time },
        { label: 'Flight Home', data: res_get_lead_contract?.data?.lead_contract?.flight_return_time },
        { label: 'Price Includes', data: res_get_lead_contract?.data?.lead_contract?.price_includes[selectedLanguage],editor:true},
        { label: 'Detail', data: res_get_lead_contract?.data?.lead_contract?.detail[selectedLanguage],editor:true},
     
        
        
    ]
    // const filteredLeadArray = leadType === 'direct'
    // ? LeadArray 
    // : LeadArray.filter(item => item.label !== 'Hotel' && item.label !== 'Airport');
    
    return (
        <div>

            {errorLead ? <div>{JSON.stringify(errorLead)}</div>
                    : isLoadingLead ? <div>Loading Single Lead.....</div>
                    : res_get_lead_contract?.data?
                    <>
                        <DZGenericView obj={LeadArray}  />
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
                                        {res_get_lead_contract?.data?.lead_contract?.prices.length > 0 ? <DZLabel title={"Lead Contract Prices"} /> : <></>}
                                        {res_get_lead_contract?.data?.lead_contract?.prices?.map(function (price, i) {

                                            return (
                                                <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                                                    <CardContent>
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                                            <DZLabelDescription title={price?.label} children={price?.price} />
                                                          
                                                       
                                                        </div>
                                                    </CardContent>
                                                </Card>)

                                        })}


                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                                        <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>

                                    </div>
                  
                                </div>
                        </>
                        : <div>Bad Code</div>}

        </div>
    )
}




export default ViewLeadContract

