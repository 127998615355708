import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllLeadUsersQuery, BLUE_PRINTS, useGetLeadUserQuery, useDeleteLeadUserMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Add from '.';
import { setSelectedLeadUser } from 'app/rkt_query/SelectedStuffReducer';
import ViewLeadUser from './ViewLeadUser';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import AddUpdateLeadUser from './AddUpdateLeadUser';

const LeadUserList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteLeadUser, responseDelete] = useDeleteLeadUserMutation();
  const { data: res_get_all, isLoading: isLoadingLeadUsers, error: errorLeadUsers } = useGetAllLeadUsersQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.leadUser
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedLeadUser(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLeadUser(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLeadUser(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedLeadUser(row)); }
  bluePrint.handleDelete = (id) => { deleteLeadUser(id); }

  


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={"LeadUsers"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="LeadUser"  showLanguageSwitcher={false}  data={res_get_all?.data?.lead_users} bluePrint={bluePrint} ViewModel={() => <ViewLeadUser />} ModalContent={() => <AddUpdateLeadUser onClose={hideDialog} />} />
         
        </div>
      </JumboDemoCard>
    </>
  )
}


export default LeadUserList