import React,{useState} from 'react'

import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import JumboRqTabs from "@jumbo/components/JumboReactQuery/JumboRqTabs";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import AppBar from '@mui/material/AppBar';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import WorkIcon from '@mui/icons-material/Work';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { DZColumn, DZRow } from "./DZForm";
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import {
  BLUE_PRINTS, useGetAllRestaurantOrdersQuery, useUpdateRestaurantOrderMutation,
} from "app/rkt_query/storeApis";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Card, Grid } from '@mui/material';
import { DZModalOrderDetail } from './DZModalOrderDetail';
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const OrderDetail = ({data,status,statusUpdate}) => {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showBackDrop, setShowBackDrop] = useState(false);

    const [circularLoading, setCircularLoading] = useState(false)
    const { showDialog, hideDialog } = useJumboDialog();
    const [updateRestaurantOrder, responseUpdate,] = useUpdateRestaurantOrderMutation();
    const [open, setOpen] = React.useState(false);




    const showUpdateDialog = (order,status) => {
      showDialog({
        showLanguageSwitcher: false,
        variant: 'confirm',
        title: 'Are you sure about updating this order?',
        // content: "You won't be able to recover this " + btnTitleAdd + ' later',
        onYes: () => updateOrderStatus(order,status),
        onNo: hideDialog
      })
      
    }
    const updateOrderStatus =  (order,status) => {

   
      const data={order_status:status}
         updateRestaurantOrder( {...data,id:order?.id}).unwrap()
         .then((payload) => {
          setShowBackDrop(true);
          setOpen(true);
           hideDialog();
        });
        //  const responseJson = await PP2Service.fetch('admin/admin-orders/' + selectedOrder.id, 'PATCH', {
        //    order_status: isCancel ? 'cancelled' : nextStatus()
        //  })
        //  if (responseJson.success) {
        //    await fetchAndUpdateStoreOrders(dispatch);
        //    setTOrders(value == 0 ? orders?.pending : value == 1 ? orders?.inProcess : orders?.ready);
        //  }
     
     }

     const handleToClose = (event, reason) => {
      if ("clickaway" == reason) return;
      setOpen(false);

  };
    const ViewDetail = (order) => {
        const   size='xl';
        showDialog({
          title: 'Order Detail',
          size,
          content: <DZModalOrderDetail order={order}  />
        });
      }


  return (
<>

{showBackDrop && (<SimpleBackdrop responseUpdate={responseUpdate} />)}
<Snackbar autoHideDuration={1500} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleToClose}  >

<Alert onClose={handleToClose} severity="success" sx={{ width: '100%' }}>
  {responseUpdate?.data?.message}
</Alert>
</Snackbar>

    <JumboScrollbar
                autoHeight
                autoHeightMin={554}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
   
            {data?.map((order, i) => {
            return (
      
                <AccordionSummary  aria-controls="panel1a-content" id="panel1a-header">
                  <Box sx={{ width: '100%' }}>
                    <Grid container columns={12} direction={'row'} justifyContent={"space-between"}>
                      <Grid item>
                        <Typography style={{ color: 'black', fontWeight: 'bold', fontSize: 16, }}>Order # {order?.id}</Typography>
                      </Grid>
                      <Grid item>
                        <Button onClick={() => { setSelectedOrder(order);ViewDetail(order); }} variant="outlined"
                          sx={{ color: 'red', borderColor: 'gold', width: '135px', mx: 1 }}
                        >View Details</Button>

                     {status&&
                     <>
                      <Button onClick={()=>{ showUpdateDialog(order,statusUpdate);}} variant="contained"
                          sx={{ backgroundColor: "#ff5e00", width: '135px', mx: 1 }}
                        >{status}</Button>

                        <Button onClick={() => showUpdateDialog(order,"cancelled")} variant="contained"
                          sx={{ backgroundColor: "purple", width: '135px', mx: 1 }}
                        >Cancel</Button>
                        </>}
                      </Grid>
                    </Grid> 
                  </Box>
                </AccordionSummary>

          
            )
          })}
            </JumboScrollbar>
            </>
  )
}

export default OrderDetail