
import { selectedCitySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useGetCityQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
const ViewCity = ({ selectId }) => {

    const selectedCity = useSelector(selectedCitySelector);
    const [CityId, setCityId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingCity, error: errorCity } = useGetCityQuery(CityId);
    // const ViewCity = ({showId}) => {
  
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
   
        setCityId(selectedCity ? selectedCity?.id : selectId)
    }, [])
    const cityArray =
        [
            { label: 'Country', data: res_get?.data?.city?.country?.name[selectedLanguage] },
            { label: 'Name', data: res_get?.data?.city?.name[selectedLanguage] },
       

        ]

    return (
        <div>

            {errorCity ? <div>{JSON.stringify(errorCity)}</div>
                : isLoadingCity ? <div>Loading Single City.....</div>
                    : res_get?.data?.city ?
                        <DZGenericView obj={cityArray}  closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewCity

