import React, { useState } from "react";
import { useAddGalleryImageMutation } from "app/rkt_query/storeApis";
import { useSelector } from "react-redux";
import { selectedHotelSelector } from "app/rkt_query/SelectedStuffReducer";
import "./IconHover.css";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import MultiImageUpload from "app/DZcomponents/MultiImageUpload/MultiImageUpload";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
const AddUpdateGallery = ({ onClose }) => {
  const { fnShowSnackBar } = useSnackBarManager();
  const selectedHotel = useSelector(selectedHotelSelector);
  const [files, setFiles] = React.useState([]);
  const [addHotelSportImage, responseAdd] = useAddGalleryImageMutation();
  const [showBackDrop, setShowBackDrop] = useState(false);
  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const uploadImages = async () => {
    const formData = new FormData();
    if (files.length === 0) {
      fnShowSnackBar("Please select images", "error");
      return;
    }
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
    }
    // files.forEach((file) => {
    //   formData.append("files", file);
    // });
    await addHotelSportImage(formData)
      .then((res) => {
        console.log(res, "res");
        if (res?.data?.message) {
          fnShowSnackBar(res?.data?.message || "Done successfully");
        } else {
          fnShowSnackBar(
            res?.error?.data?.message || "Something went wrong",
            "error"
          );
        }
      })
      .finally((res) => {
        onClose();
      });

    setFiles([]);
  };
  return (
    <>
      {responseAdd.isLoading === true && (
        <SimpleBackdrop responseAdd={responseAdd} />
      )}
      <Snackbar
        responseAdd={responseAdd?.isSuccess === true ? responseAdd : ""}
      />
      <MultiImageUpload
        onClose={onClose}
        imgfiles={files}
        setimgfiles={setFiles}
        uploadImagesfiles={uploadImages}
      />
    </>
  );
};
export default AddUpdateGallery;
