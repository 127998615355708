import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedCountrySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetCountryQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewCountry = ({ selectId }) => {

    const selectedCountry = useSelector(selectedCountrySelector);
    const [CountryId, setCountryId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingCountry, error: errorCountry } = useGetCountryQuery(CountryId);
  
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    useEffect(() => {
        
        setCountryId(selectedCountry ? selectedCountry?.id : selectId)
    }, [])
    const countryArray =
        [
            { label: 'Name', data: res_get?.data?.country?.name[selectedLanguage] },

        ]
  
    return (
        <div>

            {errorCountry ? <div>{JSON.stringify(errorCountry)}</div>
                : isLoadingCountry ? <div>Loading Single Country.....</div>
                    : res_get?.data?.country ?
                        <DZGenericView obj={countryArray} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewCountry

