import React from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const DZDropdownMultipleTranslatable = ({ value, onChange = () => { }, sx, error, data = [],label="User", fieldValueName = 'id', placeholder, fieldTextName = 'name', }) => {

    const [selectedIds, setSelectedIds] = React.useState([]);

    const handleChange = (event) => {
      setSelectedIds(event.target.value);
      onChange(event.target.value);
    };
  
    const getSelectedNames = (selectedIds) => {
      const selectedOptions = data?.filter((option) => selectedIds.includes(option.id));
      return selectedOptions?.map((option) => option.title);
    }
  
    return (
      <FormControl >
        <InputLabel id="select-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="select-multiple-checkbox-label"
          id="select-multiple-checkbox"
          multiple
          value={selectedIds}
          onChange={handleChange}
          inputProps={{
            id: 'select-multiple-checkbox',
          }}
          renderValue={(selected) => getSelectedNames(selected).join(', ')}
        >
          {data?.map((option) => (
            <MenuItem key={option?.id} value={option?.id}>
              <Checkbox
                checked={selectedIds.indexOf(option?.id) > -1}
                onChange={handleChange}
              />
              <span>{option.title}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
};

export default DZDropdownMultipleTranslatable;