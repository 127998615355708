import React from "react";
import { TextField } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
const DZTextField = ({
  label,
  noOfRows = 1,
  sx = "",
  maxRows = 3,
  error,
  variant = "outlined",
  value = "",
  type = "text",
  onChange = (e) => {},
  gridxs,
  gridmd, 
  gridlg,
  divmb,
  divmt,
}) => {
  return (
    <Grid item xs={gridxs} md={gridmd} lg={gridlg}>
      <Div sx={{ mb: divmb, mt: divmt }}>
        <TextField
          fullWidth
          id="outlined-basic"
          sx={sx}
          type={type}
          label={label}
          {...(error && { error: true, helperText: error })}
          variant={variant}
          value={value}
          onChange={onChange}
          multiline
          maxRows={maxRows}
          minRows={noOfRows}
        />
      </Div>
    </Grid>
  );
};
// fullWidth
export default DZTextField;
export const DZDateField = ({
  label,
  noOfRows = 1,
  sx = "",
  error,
  variant = "outlined",
  value = "",
  type = "date",
  onChange = (e) => {},
}) => {
  return (
    <TextField
      fullWidth
      id="outlined-basic"
      sx={sx}
      type={type}
      label={label}
      {...(error && { error: true, helperText: error })}
      variant={variant}
      value={value}
      onChange={onChange}
      multiline
      maxRows={3}
      minRows={noOfRows}
    />
  );
};
