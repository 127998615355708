import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'

import { useGetAllCitiesQuery, BLUE_PRINTS, useDeleteCityMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';

import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedCity } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';


import ViewCity from './ViewCity';
import AddUpdateCity from './AddUpdateCity.js';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';


const CityList = () => {

  const dispatch = useDispatch();


  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteCity, responseDelete] = useDeleteCityMutation();



  const { data: res_get_all, } = useGetAllCitiesQuery();
  const [showMode, setShowMode] = useState(false);
  const bluePrint = BLUE_PRINTS.city
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCity(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true);  dispatch(setSelectedCity(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCity(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedCity(row)); }
  bluePrint.handleDelete = (id) => { deleteCity(id); }
  return (
    <>
    
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title="Cities"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable

            btnTitleAdd="City"
            data={res_get_all?.data?.cities}
            bluePrint={bluePrint}
            ViewModel={() => <ViewCity />}
            ModalContent={() => <AddUpdateCity onClose={hideDialog} fullscreen={true} />}
            showAddButton={true}
          
          />
         </div>
      </JumboDemoCard>
    </>
  );
}


export default CityList