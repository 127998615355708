import { Box, Button, TextField, Fab } from "@mui/material";
import {
  selectedCitySelector,
  setSelectedCity,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddCityMutation,
  useGetCityQuery,
  useUpdateCityMutation,
  useGetAllCountriesQuery,
  useGetAllLeadUsersQuery,
  useAddUserChatMessageMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import ComboBox from "../../../@jumbo/dz-components/autocomplete-cb/ComboBox";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
const StartChatWithNewUser = ({ user_id }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const [userData, setUserData] = useState({
    message: "",
    lead_user_id: "",
  }); 
  const { data: res_get_all } = useGetAllLeadUsersQuery();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [addMessageMutation, responseMessage] = useAddUserChatMessageMutation();
  const { error, resetCount } = useResponseError(responseMessage);








  const { fnShowSnackBar } = useSnackBarManager();










  const sendMessage = async () => {
    const formData = new FormData();
    formData.append("lead_user", userData.lead_user_id);
    formData.append("message", userData.message);
    formData.append("is_mail", 0);


    const res = await addMessageMutation(formData);


    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message|| "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }

    
  };

















  useEffect(() => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      lead_user_id: user_id, 
    }));
  }, [user_id]);



  return (
    <div>
      {showBackDrop && <SimpleBackdrop responseAdd={responseMessage} />}
      <Snackbar
        responseAdd={
          responseMessage?.isSuccess === true
            ? responseMessage
            : responseMessage
        }
      />
      {res_get_all?.data?.lead_users ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.lead_user_id}
                value={userData?.lead_user_id || ""}
                data={res_get_all?.data?.lead_users}
                fieldTextName={"first_name"}
                onChange={(e) => {
                  setUserData((x) => ({
                    ...x,
                    lead_user_id: e.target.value,
                  }));
                }}
                placeholder="Select User"
              />
            </Div>
          </Grid>

          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.message}
                  label="Message"
                  variant="outlined"
                  value={userData?.message}
                  onChange={(e) => {
                    setUserData((x) => ({
                      ...x,
                      message: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                setUserData(null);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                sendMessage("add");
                setShowBackDrop(true);
                resetCount();
              }}
            >
              Send Message
            </Button>
          </div>
          {/* dispatch(setSelectedCity(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default StartChatWithNewUser;
