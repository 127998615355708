import { Box, Button, TextField, Fab } from "@mui/material";
import {
  selectedLeadServiceSelector,
  setSelectedLeadService,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddLeadServiceMutation,
  useGetLeadServiceQuery,
  useUpdateLeadServiceMutation,
  useGetAllCountriesQuery,
  useGetAllLeadServiceTypeQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import ComboBox from "../../../@jumbo/dz-components/autocomplete-cb/ComboBox";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";

import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

// import { AddCircle } from "@material-ui/icons";

const AddUpdateLeadService = ({ onClose, learning_journey_id, selectedId }) => {
  // const chatbot_types = ['scale', 'note', 'buddy'];
  const { hideDialog } = useJumboDialog();
  const types = ["Transport", "Stay", "Meal", "Workout", "YakSport", "Other"];
  const { fnShowSnackBar } = useSnackBarManager();

  const dispatch = useDispatch();

  const selectedLeadService = useSelector(selectedLeadServiceSelector);

  const { data: res_get } = useGetLeadServiceQuery(selectedLeadService?.id);
  const {
    data: res_get_all,
    isLoading: isLoadingServiceTypes,
    error: errorServiceTypes,
  } = useGetAllLeadServiceTypeQuery();

  const [updateLeadService, responseUpdate] = useUpdateLeadServiceMutation();
  const [addLeadService, responseAdd] = useAddLeadServiceMutation();

  const bluePrint = BLUE_PRINTS.leadService;

  const [leadServiceState, setLeadServiceState] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setLeadServiceState(
      selectedLeadService
        ? res_get?.data?.lead_service
        : {
            ...bluePrint,
            lead_service_type_id:
              res_get?.data?.lead_service?.lead_service_type_id ?? "",
          }
    );
  }, [res_get]);

  const validationRules = {
    lead_service_type_id: {
      required: true,
    },
    title: {
      required: true,
    },
  };

  const validateForm = () => {
    const errors = {};

    Object.keys(validationRules).forEach((field) => {
      const rules = validationRules[field];
      if (rules.required && !leadServiceState?.[field]) {
        errors[field] = "This field is required";
      }
      if (!leadServiceState.title?.en) {
        errors["title"] = "This field is required";
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length == 0;
  };

  const addUpdateLeadServiceData = async (action) => {
    const formData = new FormData();
    formData.append("id", res_get?.data?.lead_service?.id);

    formData.append("title", JSON.stringify(leadServiceState.title));

    formData.append(
      "lead_service_type_id",
      leadServiceState.lead_service_type_id
    );
    if (validateForm()) {
      const res =
        action === "add"
          ? await addLeadService(formData)
          : await updateLeadService(formData);
      if (res?.data?.message) {
        // fnShowSnackBar(res?.data?.message || "Done successfully");
      } else {
        fnShowSnackBar(res?.error?.data?.message,"error");
      }
    } else {
      console.error("Form is invalid. Please fill out all required fields.");
    }
  };
  const service_types = res_get_all?.data?.lead_service_types?.map(
    (service_type) =>
      BLUE_PRINTS.leadServiceType.translate(service_type, selectedLanguage)
  );
  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      {leadServiceState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={formErrors?.lead_service_type_id}
                value={leadServiceState?.lead_service_type_id || ""}
                data={service_types}
                onChange={(e) => {
                  setLeadServiceState((x) => ({
                    ...x,
                    lead_service_type_id: e.target.value,
                  }));
                }}
                placeholder="Select Service Type"
              />
            </Div>
          </Grid>

          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <MultiDZTextValidation
                  error={formErrors?.title}
                  label="Title"
                  variant="outlined"
                  value={leadServiceState?.title?.[selectedLanguage]}
                  onChange={(e) => {
                    setLeadServiceState((x) => ({
                      ...x,
                      title: { ...x.title, [selectedLanguage]: e.target.value },
                    }));
                  }}
                  placeholder="Title"
                />
              </Div>
            </Grid>
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedLeadService(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.lead_service?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadServiceData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLeadService(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadServiceData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLeadService(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedLeadService(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateLeadService;
