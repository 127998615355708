import React, { useEffect, useRef } from "react";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import SentMessageContent from "./SentMessageContent";
import ReceivedMessageContent from "./ReceivedMessageContent";
import { useGetUserChatQuery } from "app/rkt_query/storeApis";
import { CircularProgress, Typography } from "@mui/material";

const ActiveConversationChat = ({ conversation,id }) => {
  const { data: userConversation,isLoading } = useGetUserChatQuery(id);
  const messageRef = useRef(null);

 const fnScrollToBottom = () => {
    // messageRef?.current?.scrollIntoView({ behavior: 'smooth' }); for smoothly slow down to last message
    messageRef?.current?.scrollIntoView();
  };
  useEffect(() => { fnScrollToBottom(); }, [userConversation?.data?.messages]);

  if (isLoading) {
    return (
        <Div
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme => theme.spacing(3),
                m: 'auto',
            }}
        >
            <CircularProgress/>
            <Typography variant={'h6'} color={'text.secondary'} mt={2}>Loading messages</Typography>
        </Div>
    )
}
  return (
    <React.Fragment>
      <Div
        sx={{
          position: "relative",
          textAlign: "center",
          mb: 2,

          "&:after": {
            display: "inline-block",
            content: "''",
            position: "absolute",
            left: 0,
            right: 0,
            height: "1px",
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: "divider",
          },
        }}
      >
        <Chip
          label={conversation?.created_at}
          variant="outlined"
          sx={{
            position: "relative",
            zIndex: 1,
            bgcolor: (theme) => theme.palette.background.paper,
            borderColor: "divider",
            borderRadius: 2,
          }}
        />
      </Div>
      {/* <div style={{ height: "60dvh", overflowY: "scroll" }}> */}
        {userConversation?.data?.messages?.map((message, index) => {
          return (
            <React.Fragment key={index}  >

              {message?.from === "admin" ? (
                <SentMessageContent message={message} />
              ) : (
                <ReceivedMessageContent message={message} />
              )}
              <div ref={messageRef}  />
            </React.Fragment>
          );
        })}
      {/* </div> */}
    </React.Fragment>
  );
};
/* Todo conversation prop define */
export default ActiveConversationChat;
