
import { selectedLeadContractConditionSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useGetLeadContractConditionQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
const ViewLeadContractCondition = ({ selectId }) => {

    const selectedLeadContractCondition = useSelector(selectedLeadContractConditionSelector);
    console.log("object",selectedLeadContractCondition);
    const [LeadContractConditionId, setLeadContractConditionId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingLeadContractCondition, error: errorLeadContractCondition } = useGetLeadContractConditionQuery(LeadContractConditionId);
    // const ViewLeadContractCondition = ({showId}) => {
  console.log(LeadContractConditionId);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
   
        setLeadContractConditionId(selectedLeadContractCondition ? selectedLeadContractCondition?.id : selectId)
    }, [])
    const leadServiceArray =
        [
            { label: 'Type', data: res_get?.data?.contract_condition?.type },
            { label: 'Title', data: res_get?.data?.contract_condition?.title?.[selectedLanguage] },
       

        ]

    return (
        <div>

            {errorLeadContractCondition ? <div>{JSON.stringify(errorLeadContractCondition)}</div>
                : isLoadingLeadContractCondition ? <div>Loading Single LeadContractCondition.....</div>
                    : res_get?.data?.contract_condition ?
                        <DZGenericView obj={leadServiceArray}  closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewLeadContractCondition

