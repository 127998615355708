import React, { useState } from "react";
import { Box, Snackbar } from "@mui/material";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
  useDeleteGalleryImageMutation,
  useGetAllGalleryImagesQuery,
} from "app/rkt_query/storeApis";
import DeleteIcon from "@mui/icons-material/Delete";
import { Config } from "./../../../constant";
import AddUpdateGallery from "./AddUpdateGallery";
import { SimpleButtons } from "@jumbo/dz-components/header-buttons/HeaderButtons";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
function GalleryList() {
  const { data: galleryData } = useGetAllGalleryImagesQuery();
  const [deleteGalleryImg, responseDelete] = useDeleteGalleryImageMutation();
  const { showDialog, hideDialog } = useJumboDialog();
  const galleryImages = galleryData?.data?.gallery_images;
  const showAddDialog = () => {
    showDialog({
      title: "Upload Gallery" + "Image",
      showLanguageSwitcher: false,
      content: <AddUpdateGallery onClose={hideDialog} />,
    });
  };
  const deleteGalleryImage = (id) => {
    showDialog({
      variant: 'confirm',
      title: 'Are you sure about deleting this image ?',
      content: "You won't be able to recover this  later",
      onYes: () => deleteImage(id),
      showLanguageSwitcher: false,
      onNo: hideDialog
    })
  }
  const deleteImage = (id) => {
    deleteGalleryImg(id);
    hideDialog();


  };
  return (
    <>
      {responseDelete.isLoading === true && (
        <SimpleBackdrop responseAdd={responseDelete} />
      )}
      {responseDelete?.isSuccess === true && (
        <Snackbar responseAdd={responseDelete} />
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
        <SimpleButtons
          onClick={() => {
            showAddDialog();
          }}
          title="Add Image"
        />
        <JumboDemoCard
          title={"Gallery"}
          wrapperSx={{
            p: 0,
            backgroundColor: "background.paper",
            "&:last-child": { pb: 0 },
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
              width: "100%",
              gap: "20px",
              padding: "20px",
            }}
          >
            {galleryImages?.map((gallery, index) => (
              <div style={{ position: "relative", width: "100%" }}>
                <img
                  src={Config.digitalOceanLinkGallerySmallImg + gallery.image}
                  alt="thumbnail"
                  style={{
                    width: "100%",
                    height: "120px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
                <DeleteIcon
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    color: "#FFFFFF",
                    background: "#7352C7",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                  onClick={() => deleteGalleryImage(gallery.id)}
                />
              </div>
            ))}
          </div>
        </JumboDemoCard>
      </Box>
    </>
  );
}
export default GalleryList;