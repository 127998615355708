import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedReferenceSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetReferenceQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewReference = ({ selectId }) => {

    const selectedReference = useSelector(selectedReferenceSelector);
    const [ReferenceId, setReferenceId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingReference, error: errorReference } = useGetReferenceQuery(ReferenceId);
    // const ViewReference = ({showId}) => {
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.reference?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
        res_get?.data?.reference?.image && setSelectedFile(res_get?.data?.reference?.image)
        setReferenceId(selectedReference ? selectedReference?.id : selectId)
    }, [])
    const referenceArray =
        [
            { label: 'Title', data: res_get?.data?.reference?.title?.[selectedLanguage] },
            { label: 'Reference Description', data: res_get?.data?.reference?.description?.[selectedLanguage],editor:true },
            {}
           
            

        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorReference ? <div>{JSON.stringify(errorReference)}</div>
                : isLoadingReference ? <div>Loading Single Reference.....</div>
                    : res_get?.data?.reference ?
                        <DZGenericView obj={referenceArray} closeBtn={true} image={res_get?.data?.reference?.image} checkImage={true} imagePath={Config?.digitalOceanLinkReferenceImg}  />
                        
                        
                         : <div>Bad Code</div>}

        </div>
    )
}




export default ViewReference

