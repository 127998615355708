import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedSportSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetSportQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewSport = ({ selectId }) => {

    const selectedSport = useSelector(selectedSportSelector);
    const [SportId, setSportId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingSport, error: errorSport } = useGetSportQuery(SportId);
  
    const selectedLanguage = useSelector(selectedLanguageSelector);
   
    useEffect(() => {
        
        setSportId(selectedSport ? selectedSport?.id : selectId)
    }, [])
    const sportArray =
        [
            { label: 'Name', data: res_get?.data?.sport?.name[selectedLanguage] },

        ]
  
    return (
        <div>

            {errorSport ? <div>{JSON.stringify(errorSport)}</div>
                : isLoadingSport ? <div>Loading Single Sport.....</div>
                    : res_get?.data?.sport ?
                        <DZGenericView obj={sportArray} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewSport

