import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import MailIcon from '@mui/icons-material/Mail';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { DZColumn, DZRow } from "./DZForm";
import WorkIcon from '@mui/icons-material/Work';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

//import { } from "../../redux/ModalReducer";

export const DZModalOrderDetail = ( {order} ) => {
    // const navigation = useNavigation();
    // console.log("order_detail",order);
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { hideDialog } = useJumboDialog();
    const orderTotal = order?.order_detail?.reduce((add, order_detail) => add + order_detail.price, 0);
     const subtotal = order?.order_accessory?.reduce((add, order_accessory) => add + order_accessory.total_price, orderTotal);

    return (
    // <Modal open={true} onClose={hideModal}
    //     aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <>
      <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
        
        {/* <Box sx={[styles.containerBox, { height: '600px', width:'500px',overflowY: 'scroll'}]}>
            <Box style={{ position: 'absolute', top:0, right:-10, backgroundColor: 'white' }}>
                <IconButton aria-label="Cart" color="error" onClick={hideModal}>
                    <CancelIcon />
                </IconButton>
            </Box> */}
            <Grid container sx={{ flexGrow: 1, border:1, borderColor:'red'}} >
                <Grid item xs={12}>
                    <Typography style={{color:'black', fontWeight:'bold',fontSize: 16, padding:'20px', display:'flex', justifyContent:'center'}}> 
                        Order #{order?.id}
                    </Typography>
                </Grid>
                <Grid container xs={12} style={{height:45, alignItems:'center', backgroundColor:'#d8d8d8'}} justifyContent={"space-between"}>
                    <Grid item >
                        <Typography style={{color:'black',fontSize: 16, marginLeft:'3px'}}>{order?.user?.name} </Typography>
                        <Typography style={{color:'black',fontSize: 16, marginLeft:'3px'}}>{order?.created_at} </Typography>
                    </Grid>
                        <Typography style={{color:'black',fontSize: 16}}>Total: {subtotal}.00 Kr.  </Typography>
                </Grid>
                <Grid container xs={12} style={{height:45, alignItems:'center',}} justifyContent={"space-between"}>
                <Box display={"flex"}>
                    <AddIcCallIcon style={{marginLeft: '8px', color:'black'}}/>
                    <Typography style={{color:'black',fontSize: 16, marginLeft:'3px'}}>{order?.user?.mobile ?? "No Mobile "}</Typography>
                    </Box>
                    {/* <Button size="small" variant="outlined" style={{borderColor:'gold', color:'red', marginRight: '8px'}}>Call</Button> */}
                </Grid>
                <Grid container xs={12} style={{height:45, alignItems:'center',}} justifyContent={"space-between"}>
                    <Box display={"flex"}>
                        <MailIcon style={{marginLeft: '8px', color:'black'}}/>
                        <Typography style={{color:'black',fontSize: 16, marginLeft:'3px'}}>{order?.user?.email}</Typography>
                    </Box>
                    {/* <Button size="small" variant="outlined" style={{borderColor:'gold', color:'red', marginRight: '8px'}}>Email</Button> */}
                </Grid> 
                
                {order?.order_detail?.map((orderItem,i) => (
                <Grid container xs={12} sx={{paddingX:1}} style={{height:45, alignItems:'center'}} justifyContent={"space-between"}>
                    {/* <div key={i}> */}
                    <Grid item xs={4} key={i}>
                       <Typography style={{color:'black',fontSize: 16}}>{orderItem?.product?.title[selectedLanguage]} </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign={"center"}>
                        <Typography style={{color:'black',fontSize: 16}}>Qty:{orderItem?.number_of_items} </Typography> 
                    </Grid>
                    <Grid item xs={4} textAlign={"end"}>
                        <Typography style={{color:'black',fontSize: 16}}>{orderItem?.price} Kr.</Typography> 
                    </Grid>
                    {/* </div> */}
                </Grid>

                ))}
                { order?.order_accessory ?
                <>   
                     <Typography style={{color:'black',fontSize: 20}}><b> Accessories</b> </Typography>
                        {
                    order?.order_accessory?.map((accessoryItem,i) => (
                <Grid container xs={12} sx={{paddingX:1}} style={{height:45, alignItems:'center'}} justifyContent={"space-between"}>
                    <Grid item xs={4} key={i}>
                       <Typography style={{color:'black',fontSize: 16}}>{accessoryItem?.accessory?.title[selectedLanguage]} </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign={"center"}>
                        <Typography style={{color:'black',fontSize: 16}}>Qty:{accessoryItem?.number} </Typography> 
                    </Grid>
                    <Grid item xs={4} textAlign={"end"}>
                        <Typography style={{color:'black',fontSize: 16}}>{accessoryItem?.total_price} Kr.</Typography> 
                    </Grid>
                </Grid>

                ))
                }
                </>
                :
                <Typography style={{color:'black',fontSize: 16}}>No Accessory </Typography> }
                <Grid container xs={12} sx={{paddingX:1}} style={{height:45, alignItems:'center'}} justifyContent={"space-between"}>
                    <Grid item xs={4}/>
                    <Grid item xs={4} textAlign={"end"}>
                        <Typography style={{color:'black',fontSize: 16}}>Subtotal </Typography> 
                    </Grid>
                    <Grid item xs={4} textAlign={"end"}>
                        <Typography style={{color:'black',fontSize: 16}}>{subtotal}.00 Kr.</Typography> 
                    </Grid>
                </Grid>
                <Grid container xs={12}  style={{height:45, alignItems:'center'}} justifyContent={"space-around"}>
                    <Button size="small" variant="contained" style={{backgroundColor:"#ff5e00"}}> Accept</Button>
                    <Button size="small" variant="contained" style={{backgroundColor:"purple"}} onClick={() => { hideDialog() }} >Cancel</Button>
                </Grid>
                <DZRow>
                      {order.delivery_type === 'cod' ?
                        <WorkIcon style={{ borderRadius: '6px', marginLeft: '8px', color: '#ff5e00' }} />
                        : <DeliveryDiningIcon style={{ borderRadius: '6px', marginLeft: '8px', color: '#ff5e00' }} />
                      }
                      {order.payment_type === 'mobile' ?
                        <LocalAtmIcon style={{ borderRadius: '6px', marginRight: '22px', marginBottom: '-6px', color: '#ff5e00' }} />
                        : <CreditCardIcon style={{ borderRadius: '6px', marginRight: '22px', marginBottom: '-6px', color: '#ff5e00' }} />
                      }

                    </DZRow>
            </Grid>
            
        {/* </Box> */}
        </div>
        </>

    )
}
    
    const styles = {
      containerBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
  };
