import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
const DZDropdown = ({ value, onChange = () => { }, sx, error, name,data = [], fieldValueName = 'id', placeholder, fieldTextName = 'name', disabled }) => {

  // console.log(disabled, "disabled", data)
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth
        {...(error && { error: true })}>
        <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
        <Select
          sx={sx}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          name={name}
          label={placeholder}
          onChange={onChange}
          disabled={disabled}

        >
          {
            data?.map((item, i) => (
              <MenuItem key={i} value={item[fieldValueName]}>
                {item[fieldTextName]}
              </MenuItem>
            ))
          }
        </Select>

        {error && <FormHelperText sx={{ color: '#E73145' }}>{error}</FormHelperText>}
        {/* {hasError && <FormHelperText sx={{ color: '#E73145' }}>Required*</FormHelperText>} */}
      </FormControl>
    </Box>
  );
}

export default DZDropdown
export const DZSimpleDropDown = ({ value, onChange = () => { }, name, sx, error, data = [], fieldValueName = 'id', placeholder, fieldTextName = 'name', disabled }) => {

  return (

    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth
        {...(error && { error: true })}>
        <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
        <Select
          sx={sx}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={placeholder}
          onChange={onChange}
          disabled={false}
          name={name}
          error={error}
        >
        {data?.map((name, i) => (
  <MenuItem key={i} value={name}>
    {name}
  </MenuItem>
))}

          {/* {
              data?.map((item, i) => (
                <MenuItem key={i} value={item[fieldValueName]}>{item[fieldTextName]}</MenuItem>
              ))
            } */}
        </Select>

        {error && <FormHelperText sx={{ color: '#E73145' }}>{error}</FormHelperText>}

      </FormControl>
    </Box>



  )
}



