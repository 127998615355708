import React from "react";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { TextField } from "@mui/material";

const DZAutoCompleteAddress = ({
  handlePlaceChanged = (e) => {},
  onLoad,
  label = "",
  error,
  showValue,
  onChange,
}) => {
  const handleInputChange = (event) => {
    onChange(event);
  };
  return (
    <LoadScript
      googleMapsApiKey={"AIzaSyC_fcJ8FODEIbwm1fMmwRlkZ2QEMEip7BU"}
      libraries={["places"]}
    >
      <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={handlePlaceChanged}>
        <TextField
          fullWidth
          label={label}
          type="text"
          className="form-control"
          inputProps={{ min: 1 }}
          variant="outlined"
          value={showValue}
          noOfRows={1}
          error={error}
          onChange={handleInputChange}
        />
      </StandaloneSearchBox>
    </LoadScript>
  );
};

export default DZAutoCompleteAddress;
