import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'

// import { useGetAllPagesQuery, BLUE_PRINTS, useGetPageQuery, useDeletePageMutation,  } from 'app/rkt_query/storeApis';
import { useGetAllFaqsQuery, BLUE_PRINTS, useGetFaqsQuery, useDeleteFaqsMutation, } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

// import { setSelectedPage } from 'app/rkt_query/SelectedStuffReducer';

import { setSelectedFaqs } from 'app/rkt_query/SelectedStuffReducer';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';


import { Button, IconButton } from '@mui/material';
import { boxSX } from '@jumbo/dz-components/data-table/GlobalFunctions';
// import Ordering from './Ordering';

import ViewFaqs from './ViewFaqs';
import AddUpdateFaqs from './AddUpdateFaqs.js';


const FaqsList = () => {

  const dispatch = useDispatch();

  const location = useLocation();

  const { showDialog, hideDialog } = useJumboDialog();
  // const [deletePage, responseDelete] = useDeletePageMutation();
  const [deleteFaqs, responseDelete] = useDeleteFaqsMutation();

  const { data: res_get_all, isLoading: isLoadingFaqs, error: errorFaqs } = useGetAllFaqsQuery();
  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);

  // const bluePrint = BLUE_PRINTS.page
  const bluePrint = BLUE_PRINTS.faqs

  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedFaqs(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedFaqs(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedFaqs(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedFaqs(row)); }
  bluePrint.handleDelete = (id) => { deleteFaqs(id); }

  const navigate = useNavigate();

  const handleBack = () => {

    navigate("/categories", {
      // state: {
      //   relationalObject: res_get?.data?.learning_journey?.topic?.module?.course,
      //   learningJourneyId: location?.state?.relationalObject,
      //   module: res_get?.data?.module,
      // },
    });
  }

  // useEffect(() => {
  //     console.log(res_get_all_learning_journeys)
  // }, [res_get_all_learning_journeys])

  // console.log('res_get_all', res_get_all?.data[0]);

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}



      <JumboDemoCard
        title="FAQs"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable

            btnTitleAdd="FAQs"
            data={res_get_all?.data}
            bluePrint={bluePrint}
            ViewModel={() => <ViewFaqs />}
            ModalContent={() => <AddUpdateFaqs onClose={hideDialog} fullscreen={true} />}
            showAddButton={true}

          />

        </div>
      </JumboDemoCard>
    </>
  );
}


export default FaqsList