import React from "react";
import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import FilePreview from "./FilePriview";

const SentMessageContent = ({ message }) => {
  function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleString(); // Formats the date and time according to the user's locale
  }
  const formattedDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };
  return (
    <>
      {
      // !(message?.message && message?.attachments?.length  > 0) && (
        <Div
          sx={{
            display: "flex",
            textAlign: "right",
            alignItems: "flex-start",
            flexDirection: "row-reverse",
            mb: 2,
            px: 3,
          }}
        >
          <div className="Message-root">
            <div className="Message-item">
              {message?.message && (
                <>
                  <Typography
                    variant={"body1"}
                    color={"text.secondary"}
                    fontSize={"smaller"}
                    mb={0.5}
                  >
                    {formattedDate(formatDateTime(message?.created_at))}
                  </Typography>
                  <Paper
                    elevation={0}
                    sx={{
                      p: (theme) => theme.spacing(1.5, 2),
                      bgcolor: (theme) => theme.palette.divider,
                    }}
                  >
                    <Typography variant={"body1"}>
                      {message?.message}
                    </Typography>
                  </Paper>
                </>
              )}
              {message?.attachments?.map((data, index) => {
                return <FilePreview key={index} data={data} index={index} />;
              })}
            </div>
          </div>
        </Div>
      // )
      }
    </>
  );
};

export default SentMessageContent;
