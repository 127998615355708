import {
  Box,
  Button,
  TextField,
  Fab,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {
  selectedRegionSelector,
  setSelectedRegion,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddRegionMutation,
  useGetRegionQuery,
  useUpdateRegionMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  ErrorSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { DataGrid } from "@mui/x-data-grid";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateRegion = ({ onClose, learning_journey_id, selectedId }) => {
  const { hideDialog } = useJumboDialog();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [openError, setOpenError] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const [showHotels, setShowHotels] = useState(false);
  const selectedRegion = useSelector(selectedRegionSelector);
  const {
    data: res_get,
    isLoading: isLoadingRegion,
    error: errorRegion,
  } = useGetRegionQuery(selectedRegion?.id);
  const [updateRegion, responseUpdate] = useUpdateRegionMutation();
  const [addRegion, responseAdd] = useAddRegionMutation();
  const bluePrint = BLUE_PRINTS.region;
  const [regionState, setRegionState] = useState(null);
  const [editorState, setEditorState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  useEffect(() => {
    setRegionState(
      selectedRegion
        ? res_get?.data?.region
        : {
            ...bluePrint,
          }
    );
  }, [res_get]);
  console.log(res_get, "res_get");
  const addUpdateRegionData = async (action) => {
    const newErrors = validateHotelData();
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      setOpenError(true);
      return;
    }
    const formData = new FormData();
    formData.append("id", res_get?.data?.region?.id);
    formData.append("title", regionState.title);
    formData.append("name", regionState.name);
    formData.append("language", regionState.language);
    formData.append("domain", regionState.domain);
    formData.append("currency_symbol", regionState.currency_symbol);
    formData.append("short_currency_symbol", regionState.short_currency_symbol);
    formData.append("currency_factor", regionState.currency_factor);
    const res = action === "add" ? await addRegion(formData) : await updateRegion(formData);
    
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message|| "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };
  function isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  const validateHotelData = () => {
    let newErrors = {};
    if (isEmpty(regionState.title)) {
      newErrors = { ...newErrors, title: "Please enter the Region Title" };
    }
    if (isEmpty(regionState.name)) {
      newErrors = { ...newErrors, name: "Please enter the Region Name" };
    }
    if (isEmpty(regionState.language)) {
      newErrors = {
        ...newErrors,
        language: "Please enter the Region Language",
      };
    }
    if (isEmpty(regionState.currency_symbol)) {
      newErrors = {
        ...newErrors,
        currency_symbol: "Please enter the Region Currency Symbol",
      };
    }
    if (isEmpty(regionState.short_currency_symbol)) {
      newErrors = {
        ...newErrors,
        short_currency_symbol: "Please enter the Region Short Currency Symbol",
      };
    }
    if (isEmpty(regionState.currency_factor)) {
      newErrors = {
        ...newErrors,
        currency_factor: "Please enter the Region Currency Factor",
      };
    }

    return newErrors;
  };
  function getFirstErrorMessage(errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key];
      }
    }
    return "No errors found";
  }
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "hotelName", headerName: "First name", width: 130 },
  ];
  const rows = res_get?.data?.region?.hotels?.map((data) => {
    return {
      id: data?.id,
      hotelName: data?.name,
    };
  });

  // const rows = [
  //   { id: 1, hotelName: "Jon" },
  // ];
  return (
    <div>
      {openError && (
        <ErrorSnackbar
          error={getFirstErrorMessage(errors)}
          setOpenError={setOpenError}
        />
      )}
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      {regionState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <Grid container spacing={3.75}>
            <Grid item xs={22} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.title || errors?.title}
                  label="Region"
                  variant="outlined"
                  value={regionState?.title}
                  onChange={(e) => {
                    setRegionState((x) => ({
                      ...x,
                      title: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.name || errors?.name}
                  label="Region Symbol"
                  variant="outlined"
                  value={regionState?.name}
                  onChange={(e) => {
                    setRegionState((x) => ({
                      ...x,
                      name: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.language || errors?.language}
                  label="Region Language"
                  variant="outlined"
                  value={regionState?.language}
                  onChange={(e) => {
                    setRegionState((x) => ({
                      ...x,
                      language: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.domain || errors?.domain}
                  label="Top Level Domain e.g com,dk"
                  variant="outlined"
                  value={regionState?.domain}
                  onChange={(e) => {
                    setRegionState((x) => ({
                      ...x,
                      domain: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.currency_symbol || errors?.currency_symbol}
                  label="Currency Symbol"
                  variant="outlined"
                  value={regionState?.currency_symbol}
                  onChange={(e) => {
                    setRegionState((x) => ({
                      ...x,
                      currency_symbol: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={
                    error?.short_currency_symbol ||
                    errors?.short_currency_symbol
                  }
                  label="Short Currency Symbol"
                  variant="outlined"
                  value={regionState?.short_currency_symbol}
                  onChange={(e) => {
                    setRegionState((x) => ({
                      ...x,
                      short_currency_symbol: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  fullWidth
                  label="Currency Rate "
                  type="number"
                  inputProps={{ min: 1 }}
                  error={error?.currency_factor || errors?.currency_factor}
                  variant="outlined"
                  noOfRows={1}
                  value={regionState?.currency_factor}
                  onChange={(e) => {
                    setRegionState((x) => ({
                      ...x,
                      currency_factor: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>

            <div
              style={{
                boxShadow:'rgba(149, 157, 165, 0.1) 0px 8px 24px',
              padding:'10px',
                width: "100%",
                marginLeft: "34px",
                marginTop: "30px",
                borderRadius:'16px'
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setShowHotels(!showHotels)}
              >
                <span style={{ fontSize: "18px",fontWeight:'600'  }}>Hotels</span>
                <ArrowDropDown style={{ cursor: "pointer", rotate: `${showHotels ? "180deg": "0deg"}` }} fontSize="large" />
              </div>
              {showHotels && (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  sx={{height:350}}
                  pageSizeOptions={[5, 10]}
                  // checkboxSelection
                />
              )}
            </div>
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedRegion(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.region?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateRegionData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedRegion(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateRegionData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedRegion(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedRegion(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateRegion;
