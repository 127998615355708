import React, { useState, useRef } from "react";
import "./MultiImageUpload.css";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedHotel } from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";

function MultiImageUpload({
  onClose,
  hotelId,
  sport,
  show_image = true,
  uploadImagesfiles,
  imgfiles,
  setimgfiles,
  hotelName,
}) {
  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const fileInputRef = useRef(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const handleImageChange = (e) => {
    const files = e.target.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const selectedImagesArray = Array.from(files);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...selectedImagesArray,
    ]);
    const imagePreviews = [];
    for (let i = 0; i < selectedImagesArray.length; i++) {
      const imageURL = URL.createObjectURL(selectedImagesArray[i]);
      imagePreviews.push(imageURL);
    }
    setPreviewImages((prevPreviewImages) => [
      ...prevPreviewImages,
      ...imagePreviews,
    ]);
    setimgfiles((prevImgfiles) => [...prevImgfiles, ...selectedImagesArray]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const removeImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
    const updatedPreviews = [...previewImages];
    updatedPreviews.splice(index, 1);
    setPreviewImages(updatedPreviews);
  };

  return (
    <div
      className="popup__page"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragOver}
    >
      <div className="pop__up">
        <div
          className="header"
          style={{ flexDirection: "column", alignItems: "start", gap: "16px" }}
        >
          <span className="header__title">
            Upload Image: {hotelName}
            {sport && ","} {sport}
          </span>
        </div>
        <div style={{ width: "100%" }} className="upload__container">
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <div
            className="image__container"
            onClick={() => fileInputRef.current.click()}
          >
            {previewImages.map((imageURL, index) => (
              <div key={index} className="image__data">
                <i
                  className="close__icon ri-close-line"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeImage(index);
                  }}
                ></i>
                <div className="data__container">
                  <span className="data">
                    {(selectedImages[index].size / 1024).toFixed(2)} KB
                  </span>
                  <span className="data">
                    {selectedImages[index].name.length > 12
                      ? selectedImages[index].name.substring(0, 12) + "..."
                      : selectedImages[index].name}
                  </span>
                </div>
                <img src={imageURL} alt={`Preview ${index}`} />
              </div>
            ))}
            {previewImages?.[0] ? (
              <>
                {show_image ? (
                  <div>
                    <img src="addimg.png" alt="Upload PIC" />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="upload__name">
                <i className="ri-upload-cloud-2-fill"></i>
                <span>Add Your Images</span>
              </div>
            )}
          </div>
        </div>
        <div className="btn__container" style={{ width: "100%" }}>
          <button
            className="btn"
            onClick={() => {
              onClose();
              dispatch(setSelectedHotel(null));
            }}
          >
            Cancel
          </button>
          <button className="btn" onClick={uploadImagesfiles}>
            <i className="ri-upload-cloud-2-fill"></i> Upload Images
          </button>
        </div>
      </div>
    </div>
  );
}

export default MultiImageUpload;
