import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";

import {
  useAddHotelSportImageMutation,
  useDeleteHotelSportImageMutation,
  useGetHotelSportImageQuery,
} from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedHotelSelector,
  selectedHotelSportSelector,
  setSelectedHotel,
} from "app/rkt_query/SelectedStuffReducer";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import DndWrapper from "../extensions/dropzone/components/DndWrapper";
import { Config } from "constant";
import DeleteIcon from "@mui/icons-material/Delete";
import DZDeleteDialog from "@jumbo/dz-components/dialog/DZDeleteDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import MultiImageUpload from "app/DZcomponents/MultiImageUpload/MultiImageUpload";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const DzHotelSportImage = ({ onClose, hotel_id, hotelName }) => {
  const selectedSport = useSelector(selectedHotelSportSelector);
  const { fnShowSnackBar } = useSnackBarManager();

  const [open, setOpen] = React.useState(false);
  const [hotelImageId, setHotelImageId] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [deleteHotelSportImage, responseDeleteImage] =
    useDeleteHotelSportImageMutation();

  const [addHotelSportImage, responseAdd] = useAddHotelSportImageMutation();

  const { data: res_get_hotel_Sport_images, refetch } =
    useGetHotelSportImageQuery({ id: hotel_id, sports_id: selectedSport?.id });
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  ));

  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const uploadImages = async () => {
    setShowBackDrop(true);
    const formData = new FormData();

    formData.append("hotelImageId", hotel_id);
    formData.append("ImgSportsId", selectedSport?.id);
    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
    }
    const res = await addHotelSportImage(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
    refetch();
  };
  const deleteImage = () => {
    deleteHotelSportImage(hotelImageId)
      .unwrap()
      .then((payload) => {
        refetch();
      })
      .catch((error) => {});
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (id) => {
    setOpen(true);
    setHotelImageId(id);
  };
  const fallbackImage =
    "https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg";

  return (
    <>
      {responseDeleteImage?.isSuccess ? (
        <NoHideSnackbar
          responseAdd={
            responseDeleteImage?.isSuccess === true ? responseDeleteImage : ""
          }
        />
      ) : (
        ""
      )}
      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} />}
      <Snackbar
        responseAdd={responseAdd?.isSuccess === true ? responseAdd : ""}
      />

      {/* <JumboDemoCard
            title={"Drop Your Images"}
          
            wrapperSx={{pt: 0, backgroundColor: 'background.paper'}}
        >
            <Div sx={{ flex: 1}}>
                <DndWrapper>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <Typography variant={"body1"}>Drag 'n' drop some files here, or click to select
                            files</Typography> 
                    </div>
                </DndWrapper>
                <aside style={thumbsContainer}>{thumbs}</aside>
                
                <div style={dialogButtonsStyle} >
            <Button variant="outlined" onClick={() => { onClose(); dispatch(setSelectedHotel(null)); }}>Cancel</Button>
            {
                selectedSport?.id && <Button variant="contained" onClick={() => { uploadImages(); setShowBackDrop(true);  }}>Upload Images</Button>
               
            }
            </div>
            </Div>
        </JumboDemoCard> */}

      <MultiImageUpload
        show_image={false}
        hotelId={hotel_id}
        sport={selectedSport?.sports?.[0]}
        onClose={onClose}
        imgfiles={files}
        setimgfiles={setFiles}
        uploadImagesfiles={uploadImages}
        hotelName={hotelName}
      />

      <JumboDemoCard
        title={"Hotel Sport Images"}
        wrapperSx={{ pt: 0, backgroundColor: "background.paper" }}
        sx={{ marginTop: "40px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {res_get_hotel_Sport_images?.data?.hotel_images[0] ? (
            res_get_hotel_Sport_images?.data?.hotel_images?.map((hotel) => {
              return (
                <div style={{ position: "relative", minHeight: "160px" }}>
                  <img
                    style={{
                      width: "200px",
                      height: "190px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                    src={Config.digitalOceanLinkSportSmallImg + hotel?.image}
                    alt=""
                    // alt={hotel?.image}
                    //     onError={(e) => {
                    //   e.target.src = "Image_not_available.png"; // Replace with your fallback image URL
                    // }}
                  />
                  <div className="delete__icon">
                    <DZDeleteDialog
                      title={"Are you sure about deleting this Image?"}
                      description={
                        " You won't be able to recover this image later"
                      }
                      openBox={open}
                      handleClose={() => handleClose()}
                      handleDeleteFunctionCall={() => deleteImage(hotel.id)}
                    />
                    <DeleteIcon
                      onClick={() => {
                        handleClickOpen(hotel.id);
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <h4>No Images</h4>
            </>
          )}
        </div>
      </JumboDemoCard>
    </>
  );
};

export default DzHotelSportImage;
