import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedRegionSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetRegionQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewRegion = ({ selectId }) => {

    const selectedRegion = useSelector(selectedRegionSelector);
    const [RegionId, setRegionId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingRegion, error: errorRegion } = useGetRegionQuery(RegionId);
    // const ViewRegion = ({showId}) => {
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.region?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
        res_get?.data?.region?.image && setSelectedFile(res_get?.data?.region?.image)
        setRegionId(selectedRegion ? selectedRegion?.id : selectId)
    }, [])
    const regionArray =
        [
            { label: 'Region Name', data: res_get?.data?.region?.name },
            { label: 'Region Language ', data: res_get?.data?.region?.language},
            { label: 'Top Level Domain ', data: res_get?.data?.region?.domain},
            { label: 'Currency Symbol ', data: res_get?.data?.region?.currency_symbol },
            { label: 'Short Currency Symbol', data: res_get?.data?.region?.short_currency_symbol },
            { label: 'Currency Rate ', data: res_get?.data?.region?.currency_factor },

           
            

        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorRegion ? <div>{JSON.stringify(errorRegion)}</div>
                : isLoadingRegion ? <div>Loading Single Region.....</div>
                    : res_get?.data?.region ?
                        <DZGenericView obj={regionArray} closeBtn={true} />
                        
                        
                         : <div>Bad Code</div>}

        </div>
    )
}




export default ViewRegion

