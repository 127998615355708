// const serverUrl = process.env.REACT_APP_SERVER_URL;
// const frontendUrl = process.env.REACT_APP_FRONTEND_URL
// const image_base_url = process.env.REACT_APP_IMAGE_ENDPOINT
// const file_base_url = process.env.REACT_APP_FILE_ENDPOINT

const serverUrl = process.env.REACT_APP_SERVER_URL;
const frontendUrl = "https://yaksport.dk/"
const image_base_url = process.env.REACT_APP_IMAGE_ENDPOINT
const file_base_url =  process.env.REACT_APP_FILE_ENDPOINT

// const serverUrl = 'http://127.0.0.1:8000/';

export const Config = {

  secretPass: "XkhZG4fW2t2W",
  serverUrl: serverUrl,
  frontendUrl: frontendUrl,
  serverApiUrl: serverUrl + 'api/',
  serverUrlImages: serverUrl + 'public/images/',
  serverUrlCategoryImages: serverUrl + 'images/categories/',
  serverUrlProductImages: serverUrl + 'public/images/products/',
  serverUrlUserImages: serverUrl + 'public/images/users/',
  adminApiTokenName: 'admin-login-token',
  roleName: 'role',
  email: 'email',
  verificationCode: 'verifiactionCode',

  userApiTokenName: 'user-login-token',
  googleApiKey: 'AIzaSyD4BUDTEpTPIuhhJ2MyQ4AiQ0u3CyFlWOo',

  // Used in whole app
  currency_symbol: "DKK",
  currency: "Kr.",

  appName: "YakSport",

  pushTokenName: 'device-push-token',

  // Here add langues and then add transaltion file for languages
  languages: [
    { shortName: 'en', icon: '', longName: 'English' },
    { shortName: 'da', icon: '', longName: 'Danish' }
  ],
  firstVisitDone: "firstVisitDone",
  directory: "web-yaksport",
  defaultActivityImg: "../select-image.jpg",
  defaultImg: "select-image.jpg",
  defaultProductImageURI: 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg',

  digitalOceanLinkReferenceImg: image_base_url + "reference/",
  digitalOceanLinkHotelSmallImg: image_base_url + "hotels/small/",
  digitalOceanLinkHotelLargeImg: image_base_url + "hotels/large/",
  digitalOceanLinkHotelMediumImg: image_base_url + "hotels/medium/",
  // Sport paths
  digitalOceanLinkSportSmallImg: image_base_url + "sports/small/",
  digitalOceanLinkSportLargeImg: image_base_url + "sports/large/",
  digitalOceanLinkSportMediumImg: image_base_url + "sports/medium/",

  // User image paths
  digitalOceanLinkGallerySmallImg: image_base_url + "galleries/small/",
  digitalOceanLinkGalleryLargeImg: image_base_url + "galleries/large/",
  digitalOceanLinkGalleryMediumImg: image_base_url + "galleries/medium/",


  digitalOceanLinkMessage: file_base_url + `message/attachments/`,

  // Short name of Countries
  countries: [
    { shortName: 'dk', icon: '', name: 'Denmark' },
    { shortName: 'gb', icon: '', name: 'United Kingdom' },
    { shortName: 'se', icon: '', name: 'Sweden' },
    { shortName: 'no', icon: '', name: 'Norway' },
    { shortName: 'de', icon: '', name: 'Germany' },
  ],

}

