import React from "react";
import Div from "@jumbo/shared/Div";
import AuthUserSummary from "../AuthUserSummary";
import ChatGlobalSearch from "../ChatGlobalSearch";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { TabContext, TabPanel } from "@mui/lab";
import FavoriteConversationsList from "../FavoriteConverstionsList";
import RecentConversationsList from "../RecentConversationsList";
import ContactsList from "../ContactsList";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import { useGetAllChatUserQuery } from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedChatIdSelector,
  setSelectedChatId,
} from "app/rkt_query/SelectedStuffReducer";
import { Avatar, Button, TextField } from "@mui/material";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import StartChatWithNewUser from "../../StartChatWithNewUser";

const ChatAppSidebar = () => {
  const [activeTab, setActiveTab] = React.useState("chat");
  
  // , useAddUserChatMessageMutation, useGetUserChatQuery, useDeleteUserChatMessageMutation,
  const dispatch = useDispatch();
  const selectedChatId = useSelector(selectedChatIdSelector);
  const [searchTerm, setSearchTerm] = React.useState("");  // Added search term state
  const {
    data: res_get_all,
    refetch,
    error: errorChatUsers,
  } = useGetAllChatUserQuery();
  const { showDialog, hideDialog } = useJumboDialog();

const startWithNewUser=()=>{
  
  const size = "xl";
  showDialog({
    title: "Start Conversation With New User",
    size,
    showLanguageSwitcher: false,
    content: <StartChatWithNewUser />
  });
}
 
    // Filter users based on search term
    const filteredUsers = res_get_all?.data?.users?.filter(user => 
      (user.first_name + user.last_name).toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <React.Fragment>
      <Div sx={{ p: 2, pb: 1.25 }}>
        {/* <AuthUserSummary /> */}
        {/* <ChatGlobalSearch /> */}
      
        <TextField 
          placeholder="Search here..." 
          size="small" 
          fullWidth 
          sx={{mb:2}}
          value={searchTerm}  // Bind value to search term state
          onChange={(e) => setSearchTerm(e.target.value)}  // Update search term on input change
        />
          <Button 
         variant="contained"  
          // size="small" 
          fullWidth 
          // value={searchTerm}  // Bind value to search term state
         onClick={()=>{startWithNewUser()}}
       >Start With New User</Button>
      </Div>
      <TabContext value={activeTab}>
        <Div sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="fullWidth"
            onChange={(event, newTab) => setActiveTab(newTab)}
          >
            <Tab label="Chats" value={"chat"} />
            {/* <Tab label="Contacts" value={'contact'}/> */}
          </TabList>
        </Div>
        <JumboScrollbar
          style={{ minHeight: 200 }}
          autoHide
          autoHideDuration={200}
          autoHideTimeout={500}
          autoHeightMin={30}
        > 
          <TabPanel value={"chat"} sx={{ p: 0 }}>
            {filteredUsers?.map((user) => (
              <React.Fragment key={user.id}>
                <div
                  onClick={() => dispatch(setSelectedChatId(user?.id))}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    background: user?.id == selectedChatId && "#7352C720",
                    padding: "12px",
                    cursor: "pointer",
                  }}
                >
                  {/* <img
                    src="https://picsum.photos/200"
                    style={{ width: "40px", borderRadius: "50%" }}
                    alt="User Avatar"
                  /> */}
                  <Avatar src={user} alt={user?.first_name} sx={{ width: 40 }} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  > 
                    <span
                      style={{
                        color: "#333",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      {user?.first_name +" "+ user?.last_name}
                    </span>
                    <span
                      style={{
                        color: "#999",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                    >
                      {user.email}
                    </span>
                  </div>
                </div>
                <hr style={{ margin: "0 12px", color: "#eee" }} />
              </React.Fragment>
            ))}
          </TabPanel>
        </JumboScrollbar>
      </TabContext>
    </React.Fragment>
  );
};

export default ChatAppSidebar;
