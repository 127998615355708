import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedPageSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetPageQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewPage = ({ selectId }) => {

    const selectedPage = useSelector(selectedPageSelector);
    const [PageId, setPageId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingPage, error: errorPage } = useGetPageQuery(PageId);
    // const ViewPage = ({showId}) => {
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.page?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
        res_get?.data?.page?.image && setSelectedFile(res_get?.data?.page?.image)
        setPageId(selectedPage ? selectedPage?.id : selectId)
    }, [])
    const pageArray =
        [
            { label: 'Title', data: res_get?.data?.page?.title?.[selectedLanguage] },
            { label: 'Active', data: res_get?.data?.page?.is_active?"Yes":"No" },
            { label: 'Url', data: res_get?.data?.page?.url},
            { label: 'Meta Tile ', data: res_get?.data?.page?.meta_title },
            { label: 'Meta Description', data: res_get?.data?.page?.meta_description },
            { label: 'Page Heading ', data: res_get?.data?.page?.heading_title?.[selectedLanguage],editor:true },
            { label: 'Page Description', data: res_get?.data?.page?.heading_description?.[selectedLanguage],editor:true },
           
            

        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorPage ? <div>{JSON.stringify(errorPage)}</div>
                : isLoadingPage ? <div>Loading Single Page.....</div>
                    : res_get?.data?.page ?
                        <DZGenericView obj={pageArray} closeBtn={true} />
                        
                        
                         : <div>Bad Code</div>}

        </div>
    )
}




export default ViewPage

