import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'

import { useGetAllRegionsQuery, BLUE_PRINTS, useGetRegionQuery, useDeleteRegionMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedRegion } from 'app/rkt_query/SelectedStuffReducer';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';


import { Button, IconButton } from '@mui/material';
import { boxSX } from '@jumbo/dz-components/data-table/GlobalFunctions';
// import Ordering from './Ordering';

import ViewRegion from './ViewRegion';
import AddUpdateRegion from './AddUpdateRegion.js';


const RegionList = () => {

  const dispatch = useDispatch();

  
  const location = useLocation();

  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteRegion, responseDelete] = useDeleteRegionMutation();



  const { data: res_get_all, isLoading: isLoadingRegions, error: errorRegions } = useGetAllRegionsQuery();
  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);


  const bluePrint = BLUE_PRINTS.region
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedRegion(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedRegion(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedRegion(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedRegion(row)); }
  bluePrint.handleDelete = (id) => { deleteRegion(id); }


  const navigate = useNavigate();

  const handleBack = () => {

    navigate("/categories", {
      // state: {
      //   relationalObject: res_get?.data?.learning_journey?.topic?.module?.course,
      //   learningJourneyId: location?.state?.relationalObject,
      //   module: res_get?.data?.module,
      // },
    });
  }

  // useEffect(() => {
  //     console.log(res_get_all_learning_journeys)
  // }, [res_get_all_learning_journeys])

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}

     

      <JumboDemoCard
        title="Regions"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable

            btnTitleAdd="Region"
            data={res_get_all?.data?.regions}
            bluePrint={bluePrint}
            ViewModel={() => <ViewRegion />}
            ModalContent={() => <AddUpdateRegion onClose={hideDialog}  showLanguageSwitcher={false}  fullscreen={true} />}
            showAddButton={true}
            hideDeleteBtn={false}
            showRestoreBtn={true}
          />
         
        </div>
      </JumboDemoCard>
    </>
  );
}

export default RegionList