import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedFaqsSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetFaqsQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewFaqs = ({ selectId }) => {

    const selectedFaqs = useSelector(selectedFaqsSelector);
    const [FaqsId, setFaqsId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingFaqs, error: errorFaqs } = useGetFaqsQuery(FaqsId);
 
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
        setFaqsId(selectedFaqs ? selectedFaqs?.id : selectId)
    }, [])
    const FaqsArray =
        [
            { label: 'Question', data: res_get?.data?.question?.[selectedLanguage] },
            { label: 'Answer', data: res_get?.data?.answer?.[selectedLanguage] },
        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorFaqs ? <div>{JSON.stringify(errorFaqs)}</div>
                : isLoadingFaqs ? <div>Loading Single Faq.....</div>
                    : res_get?.data?
                        <DZGenericView obj={FaqsArray} closeBtn={true} />
                        
                        
                         : <div>Bad Code</div>}

        </div>
    )
}




export default ViewFaqs

