import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import React, { useEffect, useState } from "react";
import {
  useGetAllHotelSportsQuery,
  BLUE_PRINTS,
  useGetHotelSportQuery,
  useDeleteHotelSportMutation,
  // useGetAllCompaniesQuery
} from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import DataTable from "../../../@jumbo/dz-components/data-table/DataTable";
import AddUpdateHotelSport from "./AddUpdateHotelSport";
import { setSelectedHotelSport } from "app/rkt_query/SelectedStuffReducer";
import ViewHotelSport from "./ViewHotelSport";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { IconButton, Button, Typography } from "@mui/material";
import DzPreviews from "../extensions/dropzone/components/DzPreviews";
import DzHotelSportImage from "./DzHotelSportImage";
import DzTable from "@jumbo/dz-components/data-table/DzTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const HotelSportList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  console.log("In HotelSport company State ", location?.state?.hotelId);

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteHotelSport, responseDelete] = useDeleteHotelSportMutation();
  const {
    data: res_get_all,
    refetch,
    error: errorHotelSports,
  } = useGetAllHotelSportsQuery(location?.state?.hotelId);
  const hotelName = res_get_all?.data?.hotel_sports?.name
  // const { data: res_get_all_companies, isLoading: isLoadingCompanies, error: errorCompanies } = useGetAllCompaniesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.hotelSport;
  bluePrint.addRecord = () => {
    setShowMode(false);
    dispatch(setSelectedHotelSport(null));
  };
  bluePrint.showRecord = (row) => {
    setShowMode(true);
    dispatch(setSelectedHotelSport(row));
  };
  bluePrint.editRecord = (row) => {
    setShowMode(false);
    dispatch(setSelectedHotelSport(row));
  };
  bluePrint.deleteRecord = (row) => {
    dispatch(setSelectedHotelSport(row));
  };
  bluePrint.handleDelete = (id) => {
    deleteHotelSport(id);
  };

  console.log(
    res_get_all?.data?.hotel_sports?.id,
    "res_get_all?.data?.hotel_sports?.id"
  );

  const updateHotelSportList = () => {
    window.location.reload();
    // Call the API here or any other logic you need
    // This function will be passed to AddUpdateHotelSport as a prop
  };

  const onClose = () => {
    hideDialog();
    refetch();
  };
  //const check = modules.map((item) => item?.modules[0] ? item?.modules : null)
  const columns = ["Id", "Name"];
  const rows = [
    {
      id: res_get_all?.data?.hotel_sports?.id,
      name: hotelName,
    },
  ];
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        justifyContent: "flex-start",
      }}
    >
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && (
        <SimpleBackdrop responseAdd={responseDelete} />
      )}
      {responseDelete?.isSuccess === true && (
        <Snackbar responseAdd={responseDelete} />
      )}
      {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}
      <ArrowBackIcon style={{ cursor: "pointer", marginLeft: "35px"}} color="primary" onClick={() => navigate(-1)} />
      <DzTable
        columns={columns}
        rows={rows}
        // buttons={buttons}
        mainTitle={"Hotel"}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 7 }}>
        <JumboDemoCard
          title={"Hotel Sports"}
          wrapperSx={{
            p: 0,
            backgroundColor: "background.paper",
            "&:last-child": { pb: 0 },
          }}
        >
          <div style={{ width: "100%" }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="HotelSport"
              onlyEditIcon={true}
              hideViewBtn={false}
              hideDeleteBtn={false}
              data={res_get_all?.data?.hotel_sports?.hotel_sports}
              bluePrint={bluePrint}
              ViewModel={() => <ViewHotelSport />}
              ModalContent={() => (
                <AddUpdateHotelSport
                  onUpdateHotelSport={updateHotelSportList}
                  onClose={onClose}
                  hotelId={location?.state?.hotelId}
                />
              )}
              ImageDzDrop={() => (
                <DzHotelSportImage
                  onClose={hideDialog}
                  hotel_id={location?.state?.hotelId}
                  hotelName={hotelName}
                />
              )}
              // heirarchyId={location?.state?.obj?.id}
              // RelationalModalContent={() => <Module onClose={hideDialog} />}
              // relationalTitle="Module"
              // ddName="Companies"
              viewTitle="Add Images"
              showHideViewTitle={false}
              showAddButton={false}
              HeaderAddButton={false}
              // addAndViewTitle="Assign Users"
            />
            {/* <DataTable btnTitleAdd="category" data={res_get_all?.data?.categorys} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowHotelSport/> : <HotelSport onClose={hideDialog} />} /> */}
          </div>
        </JumboDemoCard>
      </Box>
    </div>
  );
};

export default HotelSportList;
