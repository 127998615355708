import React from 'react';
import {Button, ListItem, ListItemAvatar,List,ListItemIcon, ListItemText, Typography} from "@mui/material";

import Link from "@mui/material/Link";
import styled from "@emotion/styled";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined';
import { useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import ListItemComponent from './ListItemComponent';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));


const CourseItem = ({user_course}) => {
    

    const selectedLanguage = useSelector(selectedLanguageSelector);
    const startDateTime = user_course?.course?.course_team?.start_datetime;
    if (startDateTime) {
      const [dateString, _] = startDateTime.split(" ");
      const date = new Date(dateString.replace(/-/g, "/"));
      var formattedDate = new Intl.DateTimeFormat("en-US", { dateStyle: "long" }).format(date);
      console.log(formattedDate);
    } 
    return (
        <JumboCardQuick
            title={""}
            
            headerSx={{
                borderBottom: 1, borderColor: 'divider'
            }}
            sx={{mb: 3.75}}

        >
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                }}
            >
                <ListItemComponent data={user_course?.course?.name[selectedLanguage]} label={"Course"} icon={<MenuBookOutlinedIcon fontSize={"inherit"}/>}  />
                <ListItemComponent data={user_course?.course?.order_type} label={"Order Type"} icon={<BorderColorOutlinedIcon fontSize={"inherit"}/>}   />
                <ListItemComponent data={user_course?.course?.is_purpose==1?'Yes':'No'} label={"Is Purpose"} icon={<CrisisAlertOutlinedIcon fontSize={"inherit"}/>}   />
                <ListItemComponent data={user_course?.course?.buddy_feature==1?'Active':'In Active'} label={"Buddy Feature"} icon={<SubtitlesOutlinedIcon fontSize={"inherit"}/>}   />
                <ListItemComponent data={user_course?.course?.survey_feature==1?'Active':'In Active'} label={"Survey Feature"} icon={<PollOutlinedIcon fontSize={"inherit"}/>}   />
                <ListItemComponent data={formattedDate??"No"} label={"Course Start Date"} icon={<AccessTimeOutlinedIcon fontSize={"inherit"}/>}   />

            </List>
         </JumboCardQuick>
    );
};

export default CourseItem;
