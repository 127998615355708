import React, { useState } from "react";
import { Download } from "@mui/icons-material";
import { DeleteIcon, FileIcon } from "../../../../../icons/AllIcons";
import { Config } from "../../../../../../constant/index";

function FilePreview({ data, index, isUser, actionType = "download", handleDeleteFile }) {
  const filePath = `${Config.digitalOceanLinkMessage}${data?.file_path}`;
  const fileType = data?.file_path?.split(".").pop().toLowerCase();
  const imageTypes = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const [isImageError, setIsImageError] = useState(false);
  const handleDownload = () => {
    const updateUrl = filePath.replace(/%2F/g, "/");

    fetch(updateUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  return (
    <div
      key={index}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "6px",
        borderRadius: "8px",
        minWidth: "160px",
        maxWidth: "160px",
        padding: "24px",
        backgroundColor: isUser ? "#4A6DA7" : "#F6F8FB"
      }}
    >
      {actionType === "download" ? (
        <a href={filePath} target="_blank" rel="noopener noreferrer">
          <Download
            className="cursor-pointer"
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              color: isUser ? "#F6F8FB" : "#4A6DA7",
              cursor: "pointer"
            }}
            onClick={handleDownload}
          />
        </a>
      ) : (
        <DeleteIcon
          onClick={() => handleDeleteFile(index)}
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            color: "#C44D56",
            cursor: "pointer"
          }}
        />
      )}

      {!isImageError && imageTypes.includes(fileType) ? (
        <img src={filePath} alt="noImage" onError={() => setIsImageError(true)} style={{ width: "100%", height: "100%", borderRadius: "8px" }} />
      ) : (
        <FileIcon style={{ width: "46px", height: "46px" }} />
      )}
      <span
        style={{
          fontSize: "14px",
          color: isUser ? "#FFFFFF" : "#333333",
          width: "100%",
          textAlign: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {data?.name || data?.file_path?.split("/")?.pop()}
      </span>
    </div>
  );
}

export default FilePreview;
