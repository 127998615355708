import { selectedLeadUserSelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetLeadUserQuery } from 'app/rkt_query/storeApis';
import React from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewLeadUser = () => {

    const selectedLeadUser = useSelector(selectedLeadUserSelector);

    const userArray =
        [
            { label: 'Name', data: selectedLeadUser?.name },
            { label: 'Email', data: selectedLeadUser?.email },
            { label: 'Mobile', data: selectedLeadUser?.mobile },
        ]

    return (
        <div>

            {
                  selectedLeadUser?.id ?
                        <DZGenericView obj={userArray} closeBtn={true}  /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewLeadUser

