import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react'
// import themeStyle from '../../constants/theme-style';


export const DZColumn = ({children, style, noFlex, center}) => {
    return (
        <Box style={{
            ...style
        }
            //[
            // (noFlex || { display: "flex"}),
            // (center && {alignItems:'center'}),
            // { 
            //     display: "flex",
            //     flexDirection:"column",
            //     justifyContent:'space-between',
            //     //alignItems:'center'
            // }
            // , style
        //]
        }>
        {children}
        </Box>
    )
}

export const DZRow = ({children, style, center, noFlex}) => {
    return (
        <Box style={{ 
            display:'flex', 
            flexDirection:"row",
            justifyContent:'space-between', 
            ...style 
            }
             //[
            // (center && {alignItems:'center'}),
            // (noFlex || {flex:1}),
            //{
                // display: "flex",
                // flexDirection:"row",
                // justifyContent:'space-between',
                //alignItems:'center'
            //},
            // ,   style
         //]
        }>
        {children}
        </Box>
    )
}




