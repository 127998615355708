import React, { useState, useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List, ListItem, Avatar, ListItemIcon, ListItemAvatar, ListItemText, Typography, TextField } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Link from "@mui/material/Link";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { useNavigate } from "react-router-dom";
// import DZTextField from './../../../../../@jumbo/dz-components/textboxes/DZTextField';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import LoadingButton from "@mui/lab/LoadingButton";
import { GeneralSnackbar } from '@jumbo/dz-components/snackbar/Snackbar';
import Grid from "@mui/material/Grid";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import StoreTokenCheck from 'app/pages/storetoken/StoreTokenCheck';
import PP2Service from './../../services/index';
import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import { useGetMeQuery } from 'app/rkt_query/storeApis';





const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Profile = () => {

    const [name, setName] = useState();
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');
    const [error, setError] = useState([]);
    const { data: res_me, } = useGetMeQuery();

    const handleToClose = (event, reason) => {
        if ("clickaway" == reason) return;
        setOpen(false);
        setOpenError(false)
    };


    const handler = async (e) => {
        e.preventDefault()
        setLoading(true);
        let response = await PP2Service.fetch("admins/change-password", "post", {
            name: name,
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword,
        });
        setResponse(response)
        if (response.success) {

            setLoading(false);
            setOpen(true);


        } else {
            setOpenError(true)
            setError(response?.errors)
            setLoading(false);

        }

    }
    // console.log(response)
    useEffect(() => {
        userProfile()
    }, [])

    const userProfile = async () => {


        let response = await PP2Service.fetch("admin/me");

        if (response.success) {
            setUser(response?.data)

            setName(response?.data?.name)

        } else {

        }

    }
    return (
        <>
            {/* <GeneralSnackbar response={response?.success === true ? response : showError} /> */}

            {/* {showError ? <GeneralSnackbar /> : ''} */}
            <StoreTokenCheck />
            {/* <JumboCardQuick title={"Profile"} noWrapper> */}
            <JumboDemoCard
                title={"Profile"}
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                {/* <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                    }}
                > */}
                <div style={{ width: '100%', height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ width: '50%', height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 12 }}>
                        <Snackbar sx={{ mt: 8 }} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleToClose}  >

                            <Alert onClose={handleToClose} severity="success" sx={{ width: '100%' }}>
                                {response?.message}
                            </Alert>
                        </Snackbar>
                        <Snackbar sx={{ mt: 8 }} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openError} onClose={handleToClose}  >

                            <Alert onClose={handleToClose} severity="error" sx={{ width: '100%' }}>
                                {response?.message}
                            </Alert>
                        </Snackbar>
                        <List disablePadding sx={{ mb: 2 }}>
                            <ListItem alignItems="center" sx={{ p: theme => theme.spacing(0.5, 50) }}>

                                <Avatar src={user} alt={user?.name} sx={{ width: 60, height: 60, mb: 2 }} />

                            </ListItem>
                            <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 45) }}>

                                <ListItemText secondary={<Link variant="body1" href="#" underline="none">{user?.email}</Link>}
                                />
                            </ListItem>
                            <Grid container spacing={3.75}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                                        <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                                            <PersonOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography variant="body1" color="text.secondary">Name</Typography>}
                                            secondary={<TextField sx={{
                                                width: { sm: 200, md: 300 },

                                            }} id="name" name="name" value={name} onChange={(txt) => setName(txt.target.value)} />}
                                        />
                                    </ListItem>
                                    <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                                        <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                                            <LockClockOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography variant="body1" color="text.secondary">Old Password</Typography>}
                                            secondary={<TextField sx={{
                                                width: { sm: 200, md: 300 },

                                            }} type="password" {...(error?.old_password && { error: true, helperText: error?.old_password })} id="old_password" name="old_password" value={oldPassword} onChange={(txt) => setOldPassword(txt.target.value)} />}

                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>

                                    <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                                        <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                                            <PasswordOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography variant="body1" color="text.secondary">New Password</Typography>}
                                            secondary={<TextField sx={{
                                                width: { sm: 200, md: 300 },

                                            }} type="password" {...(error?.new_password && { error: true, helperText: error?.new_password })} id="new_password" name="new_password" value={newPassword} onChange={(txt) => setNewPassword(txt.target.value)} />}
                                        />
                                    </ListItem>
                                    <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                                        <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                                            <PasswordOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography variant="body1" color="text.secondary">Confirm Password</Typography>}
                                            secondary={<TextField sx={{
                                                width: { sm: 200, md: 300 },

                                            }} type="password" {...(error?.confirm_password && { error: true, helperText: error?.confirm_password })} id="confirm_password" name="confirm_password" value={confirmPassword} onChange={(txt) => setConfirmPassword(txt.target.value)} />}
                                        />
                                    </ListItem>
                                    <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                                        <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                                        </ListItemIcon>
                                        <ListItemText
                                            secondary={<LoadingButton
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{ mb: 3 }}
                                                onClick={handler}
                                                loading={loading}
                                            >Update</LoadingButton>}
                                        />
                                    </ListItem>
                                </Grid>
                            </Grid>




                        </List>
                    </div>
                </div>

            </JumboDemoCard>
        </>
    );
};

export default Profile;
